class QureyKeysGenerator<FilterKeys extends string[] = ['']> {
  identifier: string;

  constructor(identifier: string) {
    this.identifier = identifier;
  }

  all() {
    return [this.identifier];
  }
  listings() {
    return [...this.all(), 'LISTING'];
  }

  listing(filters?: {
    [x in FilterKeys[number]]?: string | number | string[];
  }) {
    if (filters && Object.values(filters).some((v) => v !== undefined)) {
      return [...this.listings(), filters];
    }

    return this.listings();
  }
  items() {
    return [...this.all(), 'ITEM'];
  }
  item(id: string) {
    return [...this.items(), id];
  }
}

export const QueryKeys = Object.freeze({
  UserProfile: 'USER_PROFILE',
  ProfileDetails: 'PROFILE_DETAILS',
  PracticeAddresses: new QureyKeysGenerator<['practice_id']>(
    'PRACTICE_ADDRESSES'
  ),
  States: 'STATES',
  UserStats: new QureyKeysGenerator('USER_STATS'),
  InvitedProviders: new QureyKeysGenerator<['page', 'user_type']>(
    'INVITED_PROVIDERS'
  ),
  InvitedAdmins: new QureyKeysGenerator<['page', 'user_type']>(
    'INVITED_ADMINS'
  ),
  PracticeDetails: new QureyKeysGenerator('PRACTICE'),
  Subscription: new QureyKeysGenerator('SUBSCRIPTION'),
  Balance: new QureyKeysGenerator('BALANCE'),
  PaymentMethod: new QureyKeysGenerator('PAYMENTMETHOD'),
  Orders: new QureyKeysGenerator<
    [
      'page',
      'orderType',
      'orderStatus',
      'search',
      'createdAt',
      'deliveryDate',
      'patient',
      'practice'
    ]
  >('ORDERS'),
  InvitedPatients: new QureyKeysGenerator<['page']>('INVITED_PATIENTS'),
  PatientDetails: new QureyKeysGenerator('PATIENT'),
  ProviderDetails: new QureyKeysGenerator('PROVIDER'),
  Providers: new QureyKeysGenerator<['page', 'search']>('PROVIDERS'),
  Patients: new QureyKeysGenerator<['page', 'search']>('PATIENTS'),
  Practices: 'Practices',
  PatientPrescription: new QureyKeysGenerator<['category', 'id']>(
    'PATIENT_PRESCRIPTION'
  ),
  PatientQuestionnaire: new QureyKeysGenerator<['gender', 'id']>(
    'PATIENT_QUESTIONNAIRE'
  ),
  PatientPrescriptions: new QureyKeysGenerator<
    ['category', 'search', 'page', 'status', 'patientId']
  >('PATIENT_PRESCRIPTIONS'),
  BillingPlans: new QureyKeysGenerator('BILLING_PLANS'),
  ProrationAmount: new QureyKeysGenerator<['priceIds', 'prorationTime']>(
    'ProrationAmount'
  ),
});
