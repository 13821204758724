import classNames from 'classnames';

import { Pagination } from '../Pagination/Pagination';
import { Typography } from '../Typography/Typography';
import { TableHeadItem } from './TableHeadItem';
import { TableRow } from './TableRow';
import { TableSkeleton } from './TableSkeleton';
import { ColumnObject } from './types';

type PaginationProps =
  | {
      pagination?: true;
      page: number;
      totalRecords: number;
      onPageChange: (page: number) => void;
    }
  | {
      pagination?: false;
      page?: never;
      totalRecords?: never;
      onPageChange?: never;
    };

type TableProps<TItem> = {
  columns: ColumnObject<TItem>[];
  rowsData: TItem[];
  loading?: boolean;
  onRowClick?: (item: TItem) => void;
  recordsPerPage?: number;
  noDataMessage?: string;
  isSticky?: boolean;
} & PaginationProps;

export function Table<TItem>({
  rowsData,
  columns,
  pagination,
  loading = false,
  onRowClick,
  page,
  totalRecords,
  onPageChange,
  recordsPerPage = 4,
  noDataMessage,
  isSticky = false,
}: TableProps<TItem>) {
  // if (loading) {
  //   return (
  //     <TableSkeleton recordsPerPage={recordsPerPage} columns={columns.length} />
  //   );
  // }

  return (
    <div className="relative overflow-auto rounded">
      <div className="table-scrollbar relative overflow-auto rounded">
        <table
          className={classNames(
            'w-full !border-separate !border-spacing-0 text-left text-sm',
            {
              'rounded-md': !pagination,
            }
          )}>
          <thead className="rounded bg-primary-main font-normal text-primary-contrastText">
            <tr className="">
              {columns.map((column: ColumnObject<TItem>, index) => (
                <TableHeadItem<TItem>
                  isSticky={
                    !loading &&
                    isSticky &&
                    [columns.length - 1, 0, 1].includes(index)
                  }
                  columnIndex={index}
                  columnsLength={columns.length}
                  key={column.title}
                  column={column}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <TableSkeleton<TItem>
                recordsPerPage={recordsPerPage}
                columns={columns}
              />
            ) : (
              rowsData?.map((item: TItem, index: number) => {
                if (index <= 9) {
                  return (
                    <TableRow<TItem>
                      // eslint-disable-next-line
                      key={index}
                      item={item}
                      onRowClick={onRowClick}
                      columns={columns}
                      isSticky={isSticky && !loading}
                    />
                  );
                }

                return null;
              })
            )}
          </tbody>
        </table>
        {rowsData[0] || loading ? null : (
          <div className="flex h-10 w-full justify-center bg-background-main">
            <Typography variant="subtitle1" color="textMuted">
              {noDataMessage}
            </Typography>
          </div>
        )}
      </div>
      {pagination && rowsData[0] && (
        <div className="flex w-full items-center justify-end rounded-b bg-background-main  p-3">
          <Pagination
            page={page}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}
