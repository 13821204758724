import React, { useState } from 'react';

import { Typography } from 'components/common/Typography/Typography';
import { useProfile } from 'hooks/useProfile';
import { formatPhoneNumber } from 'utils/common';

import { UpdateEmail } from '../UpdateEmail/UpdateEmail';
import { UpdatePhone } from '../UpdatePhone/UpdatePhone';
import { ChangeButton } from './ChangeButton';

export function UserProfileDetails() {
  const { profile } = useProfile();
  const [phoneModal, setPhoneModal] = useState<boolean>(false);
  const [emailModal, setEmailModal] = useState<boolean>(false);

  const closePhoneModal = (state: boolean) => {
    setPhoneModal(state);
  };

  const openPhoneModal = () => {
    setPhoneModal(true);
  };

  const closeEmailModal = (state: boolean) => {
    setEmailModal(state);
  };

  const openEmailModal = () => {
    setEmailModal(true);
  };

  return (
    <div className="flex rounded-md p-8">
      <div className="grow">
        <div className="grid grid-cols-12 gap-4">
          <Typography className="col-span-3" variant="h2">
            {`${profile?.firstName} ${profile?.lastName}`}
          </Typography>
        </div>
        <div className="mt-3 grid grid-cols-12 gap-4">
          <div className="col-span-5 flex">
            <Typography variant="subtitle1">
              Phone : {formatPhoneNumber(profile?.phone) ?? 'NA'}
            </Typography>
            <ChangeButton onClick={openPhoneModal} />
          </div>
          <div className="col-span-7 flex">
            <Typography variant="subtitle1">
              Email : {`${profile?.email} `}
            </Typography>
            <ChangeButton onClick={openEmailModal} />
          </div>
        </div>
      </div>
      <UpdatePhone
        phone={profile?.phone || ''}
        modal={phoneModal}
        closeModal={closePhoneModal}
      />
      <UpdateEmail
        email={profile?.email || ''}
        modal={emailModal}
        closeModal={closeEmailModal}
      />
    </div>
  );
}
