import React, { ReactNode } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Table } from 'components/common/Table/Table';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { MedicationService } from 'services/medication-prescription';
import { getReadableDate } from 'utils/common';

type Category = 'future' | 'current' | 'past';

interface OverviewTableProps {
  title: string;
  category: Category;
  id: string;
  elementAfterHeading?: ReactNode;
}

export function OverviewTable({
  title,
  category,
  id,
  elementAfterHeading,
}: OverviewTableProps) {
  const getPatientPrescriptionQuery = useQuery(
    QueryKeys.PatientPrescription.listing({ category, id }),
    () =>
      MedicationService.getPatientPrescription({
        category,
        id,
      }),
    { enabled: !!id }
  );

  const getNoDataMessage = (c: Category) => {
    switch (c) {
      case 'future': {
        return 'No future rx(s) found';
      }
      case 'past': {
        return 'No past rx(s) found';
      }
      case 'current': {
        return 'No current rx(s) found';
      }
      default:
        return 'No records found';
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-6">
        <Typography variant="h5">{title}</Typography>
        {!!elementAfterHeading && elementAfterHeading}
      </div>
      <div className="mt-4">
        <Table
          columns={[
            {
              title: 'Name',
              render: (rowData) => rowData.prescriptionToMedication.name,
            },
            {
              title: 'Strength',
              render: ({ strength }) =>
                strength?.value ? `${strength.value} ${strength.unit}` : 'NA',
            },
            {
              title: 'Dose',
              render: ({ deliveryMethodAndSig }) =>
                deliveryMethodAndSig?.dose?.value
                  ? `${deliveryMethodAndSig.dose.value} ${deliveryMethodAndSig.dose.unit}`
                  : 'NA',
            },
            {
              title: 'DTD',
              render: ({ dtd }) =>
                dtd?.value ? `${dtd.value} ${dtd.unit}` : 'NA',
            },
            {
              title: 'SIG',
              render: (rowData) => (
                <>
                  <span
                    data-tooltip-id={rowData.deliveryMethodAndSig.sig}
                    className="ellipses-text">
                    {rowData.deliveryMethodAndSig.sig}
                  </span>
                  <Tooltip id={rowData.deliveryMethodAndSig.sig}>
                    {rowData.deliveryMethodAndSig.sig}
                  </Tooltip>
                </>
              ),
            },
            {
              title: 'Refills',
              render: (rowData) => rowData.refills,
              classNames: 'text-center',
            },
            {
              title: 'Start Date',
              render: (rowData) =>
                rowData?.startDate ? getReadableDate(rowData.startDate) : 'NA',
              classNames: 'text-center',
            },
            {
              title: 'End date',
              render: (rowData) =>
                rowData?.endDate ? getReadableDate(rowData.endDate) : 'NA',
              classNames: 'text-center',
            },
          ]}
          rowsData={getPatientPrescriptionQuery.data?.data || []}
          noDataMessage={getNoDataMessage(category)}
          loading={getPatientPrescriptionQuery?.isLoading}
        />
      </div>
    </div>
  );
}
