import React from 'react';

import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Control, FieldErrorsImpl } from 'react-hook-form';

import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { extractValidationErrors } from 'lib/general/extractors';
import { OnboardServiceNS } from 'services/onboard/types';
import { ServiceErrorResponse } from 'types/common';

interface BasicInformationProps {
  control: Control<{
    phone: string;
    firstName: string;
    lastName: string;
    password: string;
  }>;
  errors: Partial<FieldErrorsImpl>;
  adminOnboardMutation: UseMutationResult<
    AxiosResponse<unknown>,
    ServiceErrorResponse<string | number | symbol>,
    OnboardServiceNS.OnboardPracticeAdminBody,
    unknown
  >;
}

export function BasicInformation({
  control,
  errors,
  adminOnboardMutation,
}: BasicInformationProps) {
  // const [code, setCode] = useState<CountryCode>();

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <Typography variant="h5">Basic Information</Typography>
      </div>
      <div className="grid grid-cols-6">
        <div className="col-span-6 flex flex-col">
          <div className=" mt-4 flex justify-between">
            <TextInput
              name="firstName"
              type="text"
              control={control}
              helperText={
                extractValidationErrors(
                  'firstName',
                  adminOnboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'firstName',
                  adminOnboardMutation,
                  errors
                ).hasError
              }
              placeholder="First Name"
              className=" "
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              name="lastName"
              type="text"
              control={control}
              helperText={
                extractValidationErrors(
                  'lastName',
                  adminOnboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'lastName',
                  adminOnboardMutation,
                  errors
                ).hasError
              }
              placeholder="Last Name"
              className=" "
              fullWidth
            />
          </div>
          <div className=" mt-0 flex justify-between">
            <TextInput
              type="password"
              name="password"
              control={control}
              helperText={
                extractValidationErrors(
                  'password',
                  adminOnboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'password',
                  adminOnboardMutation,
                  errors
                ).hasError
              }
              placeholder="Password"
              fullWidth
            />
            <span className="mx-2" />
            <TextInput
              type="password"
              name="confirmPassword"
              control={control}
              helperText={
                extractValidationErrors(
                  'confirmPassword',
                  adminOnboardMutation,
                  errors
                ).msg
              }
              error={
                extractValidationErrors(
                  'confirmPassword',
                  adminOnboardMutation,
                  errors
                ).hasError
              }
              placeholder="Confirm Password"
              fullWidth
            />
          </div>
          <div className="flex items-center space-x-3">
            <TextInput
              type="tel"
              placeholder="Phone Number"
              className="placeholder:text-zinc-400 "
              fullWidth
              name="phone"
              control={control}
              helperText={
                extractValidationErrors('phone', adminOnboardMutation, errors)
                  .msg
              }
              error={
                extractValidationErrors('phone', adminOnboardMutation, errors)
                  .hasError
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
