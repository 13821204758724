import React, {
  KeyboardEvent,
  LegacyRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { DayPicker } from 'react-day-picker';
import { createPortal } from 'react-dom';
import { IoIosCloseCircle } from 'react-icons/io';
import { IoFilterSharp } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  countDoubleQuotes,
  getReadableDate,
  splitStringWithQuotes,
} from 'utils/common';

import { Tooltip } from '../Tooltip/Tooltip';

import 'react-day-picker/dist/style.css';
import './Filter.css';

const dropdownClasses =
  'absolute w-full dropdown-scrollbar !m-0 max-h-[170px] p-2 overflow-auto top-full left-0 bg-background-light z-10 flex  translate-y-1 flex-col rounded-md';

type Option = {
  displayName: string;
  urlValue: string;
};

type FilterType = 'string' | 'date' | 'select';
interface FilterProps {
  filters: Array<{
    type: FilterType;
    urlKey: string;
    options?: Array<Option>;
  }>;
  onFilterChange: () => void;
}
export function Filter({ filters, onFilterChange }: FilterProps) {
  const [input, setInput] = useState<string>('');
  const [filterArray, setFilterArray] = useState<Array<Array<string>>>([]);
  const [toggleKeyValueDropdown, setToggleKeyValueDropdown] =
    useState<boolean>(false);
  const [currentQueryKey, setCurrentQueryKey] = useState<string>('');
  const [currentQueryValue, setCurrentQueryValue] = useState<string>('');
  const [hasUnmatchedQuotes, setHasUnmatchedQuoates] = useState<boolean>(false);
  // eslint-disable-next-line
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<{
    [x: string]: boolean;
  }>({});
  const inputRef = useRef<HTMLInputElement>();
  const divRef = useRef<HTMLDivElement>();
  const selectedIndex = useRef<number>(-1);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const constructInput = (newFilterArray: string[][]): string => {
    let inputStr = '';
    newFilterArray.forEach((filter) => {
      const [queryKey = '', queryValue = ''] = filter;
      inputStr += `${queryKey}${queryValue} `;
    });

    return inputStr.slice(0, -1);
  };

  // Handles click events on the dropdown menu and updates the filter accordingly____________________________

  const handleDropDownClick = (selectedKey: string, selectedValue = '') => {
    const value = selectedValue.includes(' ')
      ? `"${selectedValue}"`
      : selectedValue;
    const newFilterArray = [...filterArray];
    newFilterArray.pop();
    newFilterArray.push([`${selectedKey}:`, value]);
    const constructedInput = constructInput(newFilterArray);
    setInput(constructedInput);
    setFilterArray(newFilterArray);
    if (toggleKeyValueDropdown) {
      setCurrentQueryKey('');
      setCurrentQueryValue(value);
    } else {
      setCurrentQueryKey(selectedKey);
      setCurrentQueryValue('');
    }
    setToggleKeyValueDropdown(!toggleKeyValueDropdown);
    inputRef.current?.focus();
  };

  // END HERE________________________________________________________________________________________________

  // Returns a list of supported filters based on the query input____________________________________________

  const getSupportedKeys = (key: string) =>
    filters.filter((filter) =>
      filter.urlKey.toLowerCase().startsWith(key.toLowerCase())
    );

  const getSupportedValues = (key: string, value: string) =>
    filters
      .find((filter) =>
        filter.urlKey.toLowerCase().startsWith(key.toLowerCase())
      )
      ?.options?.filter((option) =>
        option.displayName.toLowerCase().startsWith(value.toLowerCase())
      ) || [];

  // END HERE________________________________________________________________________________________________

  // Returns a boolean indicating whether a filter is valid or not___________________________________________

  const isValidKey = (key: string) =>
    !hasUnmatchedQuotes &&
    (!key.includes(':') ||
      !!filters.find((filter) => `${filter.urlKey}:` === key.toLowerCase()));

  const isValidValue = (key: string, value: string) => {
    const normalisedValue = value?.replace(/"/g, '');

    return (
      !isValidKey(key) ||
      !!filters
        .find(
          (filter) => `${filter.urlKey.toLowerCase()}:` === key.toLowerCase()
        )
        ?.options?.find(
          (option) =>
            option.displayName.toLowerCase() === normalisedValue.toLowerCase()
        )
    );
  };

  // END HERE________________________________________________________________________________________________

  // Logic for ADDING, DELETING and UPDATING filter and converting them into a 2D Array ____________________

  const createArrayFromUserInput = (userInput: string) =>
    splitStringWithQuotes(userInput).map((query) => {
      const colonIndex = query.indexOf(':');
      if (colonIndex !== -1) {
        const queryValue = query.slice(colonIndex + 1);
        const queryKey = query.slice(0, colonIndex + 1);
        setCurrentQueryValue(queryValue);
        setToggleKeyValueDropdown(true);

        return [queryKey, queryValue];
      }
      setCurrentQueryKey(query);
      setToggleKeyValueDropdown(false);

      return [query];
    });

  const handleUserInput = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setInput(value);
    const queries = createArrayFromUserInput(value);
    setFilterArray(queries);
    onFilterChange();
  };

  // ENDS HERE _________________________________________________________________________________________

  // Returns a boolean indicating whether a filter is of type "date"____________________________________

  const isDateFilter = (queryKey: string) =>
    filters.find(
      (fil) => fil.urlKey.toLowerCase() === queryKey.replace(/:/, '')
    )?.type === 'date';

  // ENDS HERE _________________________________________________________________________________________

  // Filter RESET logic ________________________________________________________________________________

  const handleClear = () => {
    setInput('');
    setFilterArray([]);
    setCurrentQueryKey('');
    setCurrentQueryValue('');
    selectedIndex.current = -1;
  };

  // ENDS HERE _________________________________________________________________________________________

  // Handles Key navigation and selection on the dropdown menu__________________________________________

  const updateSelectedItem = (list: HTMLUListElement) => {
    for (let i = 0; i < list.children.length; i += 1) {
      list.children[i].classList.remove('bg-zinc-700');
    }

    const item = list.children[selectedIndex.current];
    item.scrollIntoView({
      inline: 'end',
      block: 'end',
      behavior: 'smooth',
    });
    item.classList.add('bg-zinc-700');

    return item;
  };

  const handleArrowKeyNavigation = (e: KeyboardEvent) => {
    const list = document.getElementById(
      toggleKeyValueDropdown ? 'optionList' : 'filterList'
    ) as HTMLUListElement;
    if (!list) return;
    const { key } = e;
    if (key === 'ArrowUp') {
      e.preventDefault();
      selectedIndex.current =
        (selectedIndex.current - 1 + list.children.length) %
        list.children.length;
      updateSelectedItem(list);
    } else if (key === 'ArrowDown') {
      e.preventDefault();
      selectedIndex.current =
        (selectedIndex.current + 1) % list.children.length;
      updateSelectedItem(list);
    } else if (key === 'Enter') {
      e.preventDefault();
      const itemValue =
        list.children[selectedIndex.current]?.getAttribute('data-value') || '';
      if (!itemValue) return;
      if (toggleKeyValueDropdown) {
        handleDropDownClick(currentQueryKey, itemValue);

        return;
      }
      handleDropDownClick(itemValue);
    }
  };

  // ENDS HERE _________________________________________________________________________________________

  // Adjust the scroll position of the overlapping dummy input based on the hidden input field__________

  const handleInputScroll = () => {
    const scrollLeft = inputRef.current?.scrollLeft;
    if (divRef.current) divRef.current.style.marginLeft = `-${scrollLeft}px`;
  };

  // ENDS HERE _________________________________________________________________________________________

  // Logic for showing tooltip on the hidden dummy input as it can not respond to native mouse events___

  const handleMouseOver = (clientX: number) => {
    const inputCordsLeft = inputRef.current?.getBoundingClientRect().left || 0;
    const relativeClientX = Math.ceil(clientX - inputCordsLeft);
    const elementsWithError = document.querySelectorAll(
      '[data-error*="hasError"]'
    );
    elementsWithError.forEach((elm) => {
      const index = elm.getAttribute('data-error')?.slice(-1);
      if (!index) return;
      const cords = elm.getBoundingClientRect();
      const cordsLeft = cords.left - inputCordsLeft;
      const cordsRight = cords.right - inputCordsLeft;
      if (relativeClientX >= cordsLeft && relativeClientX <= cordsRight) {
        setActiveTooltipIndex((prev) => ({ ...prev, [index]: true }));
      } else {
        setActiveTooltipIndex((prev) => ({ ...prev, [index]: false }));
      }
    });
  };

  // ENDS HERE _________________________________________________________________________________________

  // Constructs URL search params based on the added filters____________________________________________

  const buildQuery = (userInput: string) => {
    searchParams.set('Query', userInput);
    navigate({
      search: searchParams.toString(),
    });
  };

  // ENDS HERE _________________________________________________________________________________________

  useEffect(() => {
    selectedIndex.current = -1;
    buildQuery(input);
    setHasUnmatchedQuoates(countDoubleQuotes(input) % 2 !== 0);
    // eslint-disable-next-line
  }, [input]);

  return (
    <div
      id="filter-wrapper"
      className="relative flex h-11 w-full items-center space-x-3 rounded-md bg-background-light px-5">
      <IoFilterSharp className="shrink-0 text-2xl text-background-contrastText" />
      <div className="scrollbar-hide relative z-20 flex h-full w-full cursor-text overflow-visible overflow-x-auto">
        <div
          ref={divRef as LegacyRef<HTMLDivElement>}
          className="absolute left-0 top-[50%] flex h-full w-full -translate-y-[50%]  whitespace-pre">
          {filterArray.map((filter, index) => {
            const [queryKey, queryValue] = filter;
            const isValidFilter = isValidKey(queryKey);
            const isValidOption = isValidValue(queryKey, queryValue);
            const isFilterTypeDate = isDateFilter(queryKey);
            const filterTooltipId = queryKey + index;
            const optionTooltipId = queryValue + index;

            return (
              <>
                {queryKey && (
                  <div
                    data-error={!isValidFilter && `hasError_${index}`}
                    className={classNames('flex w-max items-center', {
                      ' wavy_underline_red ': !isValidFilter,
                    })}>
                    <span
                      data-tooltip-id={filterTooltipId}
                      className="relative  inline-block whitespace-pre text-base text-background-contrastText">
                      {queryKey}
                    </span>
                    {queryValue && (
                      <span
                        data-error={!isValidOption && `hasError_${index}`}
                        data-tooltip-id={optionTooltipId}
                        className={classNames(
                          'relative inline-block whitespace-pre text-base  text-primary-light',
                          {
                            ' wavy_underline_slate ':
                              !isFilterTypeDate && !isValidOption,
                          }
                        )}>
                        {queryValue}
                      </span>
                    )}
                  </div>
                )}
                {createPortal(
                  <>
                    {!hasUnmatchedQuotes && !isValidFilter && (
                      <Tooltip
                        isOpen={activeTooltipIndex[index] ?? false}
                        id={filterTooltipId}
                        place="bottom"
                        className="space-x-1 !py-1.5 !text-base">
                        Invalid filter: &quot;{queryKey}&quot;
                      </Tooltip>
                    )}
                    {!isFilterTypeDate &&
                      !hasUnmatchedQuotes &&
                      !isValidOption && (
                        <Tooltip
                          isOpen={activeTooltipIndex[index] ?? false}
                          id={optionTooltipId}
                          place="bottom"
                          className="space-x-1 !py-1.5 !text-base">
                          Invalid value: &quot;{queryValue?.replace(/"/g, '')}
                          &quot;
                        </Tooltip>
                      )}
                  </>,
                  document.body
                )}

                <span className="text-[9.2px] text-transparent">-</span>
              </>
            );
          })}
        </div>
        <div className="relative w-full overflow-x-auto overflow-y-visible">
          {!input && (
            <div className="pointer-events-none absolute left-0 flex h-full w-max select-none items-center whitespace-nowrap text-md text-zinc-400">
              Filter by fields
            </div>
          )}
          <input
            role="combobox"
            aria-expanded
            aria-controls="search-suggestion-box"
            spellCheck={false}
            autoCorrect="off"
            autoComplete="off"
            value={input}
            ref={inputRef as LegacyRef<HTMLInputElement>}
            onChange={handleUserInput}
            onKeyDown={handleArrowKeyNavigation}
            onMouseMove={(e) => handleMouseOver(e.clientX)}
            onMouseLeave={() => setActiveTooltipIndex({})}
            onScroll={handleInputScroll}
            type="text"
            data-tooltip-id="tooltip_unmatched_filter"
            className="b-0 relative h-full w-full select-none resize-none bg-transparent p-0 text-base text-transparent caret-white outline-none"
          />
        </div>
      </div>
      <button onClick={handleClear} type="button">
        <IoIosCloseCircle className="text-2xl text-background-contrastText active:scale-125" />
      </button>
      {hasUnmatchedQuotes && (
        <Tooltip
          className="z-10 space-x-1 !py-1.5 !text-base"
          place="bottom"
          id="tooltip_unmatched_filter">
          Invalid filter: Unmatched quotation mark
        </Tooltip>
      )}
      {!toggleKeyValueDropdown &&
        currentQueryKey &&
        (getSupportedKeys(currentQueryKey).length ? (
          <ul id="filterList" className={classNames(dropdownClasses)}>
            {getSupportedKeys(currentQueryKey).map((filter) => (
              <>
                {/* eslint-disable-next-line */}
                <li
                  data-value={filter.urlKey}
                  onClick={() => handleDropDownClick(filter.urlKey)}
                  className="w-full cursor-pointer rounded-md px-2 py-2 text-start text-base text-background-contrastText hover:bg-zinc-700">
                  {filter.urlKey}:
                </li>
              </>
            ))}
          </ul>
        ) : null)}
      {toggleKeyValueDropdown &&
        currentQueryKey &&
        (!isDateFilter(currentQueryKey) ? (
          getSupportedValues(currentQueryKey, currentQueryValue).length ? (
            <ul id="optionList" className={classNames(dropdownClasses)}>
              {getSupportedValues(currentQueryKey, currentQueryValue).map(
                (option) => (
                  <>
                    {/* eslint-disable-next-line */}
                    <li
                      data-value={option.displayName}
                      onClick={() =>
                        handleDropDownClick(currentQueryKey, option.displayName)
                      }
                      className="w-full cursor-pointer rounded-md px-2 py-2 text-start text-base text-background-contrastText hover:bg-zinc-700">
                      {option.displayName}
                    </li>
                  </>
                )
              )}
            </ul>
          ) : null
        ) : (
          <div className="absolute top-full z-10">
            <DayPicker
              className="m-0 mt-1 w-max rounded-sm bg-background-light p-4 text-background-contrastText shadow-2xl [&_.rdp-cell>button]:!rounded-none [&_.rdp-day:hover]:!bg-white [&_.rdp-day:hover]:!text-black [&_.rdp]:!bg-background-light"
              mode="single"
              modifiersClassNames={{
                today:
                  '!text-primary-main !font-semibold !border-2 !border-primary-main',
              }}
              styles={{
                cell: {
                  fontSize: '0.9rem',
                },
                head_cell: {
                  color: '#359583',
                },
                dropdown: {
                  backgroundColor: '#262626',
                  color: 'whitesmoke',
                  border: 'none',
                  fontSize: '0.8rem',
                },
              }}
              fromYear={new Date().getFullYear()}
              toYear={new Date('2030-01-01').getFullYear()}
              onDayClick={(date) => {
                const readableDate = getReadableDate(date.toISOString());
                handleDropDownClick(currentQueryKey, readableDate);
              }}
              captionLayout="dropdown-buttons"
            />
          </div>
        ))}
    </div>
  );
}
