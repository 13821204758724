import React, { useCallback } from 'react';

import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { getReadableDate } from 'utils/common';

type RxPatientProps = {
  isFetchingNextPage: boolean;
  onNextPage: () => void;
  onRowClick: (id: string) => void;
  patientWithRx: {
    id: string;
    category: string;
    status: string;
    createdAt: string;
    prescriptionToPatient: {
      id: string;
      patientDetails: {
        dob: string;
      };
      name: string;
      email: string;
    };
    prescriptionToProvider: {
      id: string;
      name: string;
      email: string;
    };
  }[];
};

export function PatientsWithRx({
  patientWithRx,
  onNextPage,
  isFetchingNextPage,
  onRowClick,
}: RxPatientProps) {
  const onPatientWithRxScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
        onNextPage();
      }
    },
    [onNextPage]
  );

  return (
    <div>
      {patientWithRx.length ? (
        <div className="relative rounded-md">
          <ul
            onScroll={onPatientWithRxScroll}
            className="dropdown-scrollbar flex max-h-[20rem] flex-col space-y-2 overflow-auto pr-2 pt-0.5">
            {patientWithRx.map(
              ({
                id,
                prescriptionToProvider,
                prescriptionToPatient,
                createdAt,
              }) => (
                <li>
                  <button
                    onClick={() => onRowClick(prescriptionToPatient.id)}
                    type="button"
                    className="flex items-center rounded-lg bg-background-main  p-5 py-4 hover:bg-background-light"
                    key={id}>
                    <Typography variant="subtitle2">
                      <span>
                        Click here to confirm the payment against the
                        prescriptions raised by provider{' '}
                        <span className="text-primary-main">
                          {prescriptionToProvider.name}
                        </span>{' '}
                        for patient{' '}
                        <span className="text-primary-main">
                          {prescriptionToPatient.name}
                        </span>{' '}
                        on {getReadableDate(createdAt)}.
                      </span>
                    </Typography>
                  </button>
                </li>
              )
            )}
          </ul>
          {isFetchingNextPage ? (
            <li className="mt-2 flex justify-center">
              <LoadingSpinner className="text-primary-main" />
            </li>
          ) : null}
        </div>
      ) : (
        <Typography variant="subtitle2" color="textMuted">
          No patients requiring Rx(s) action.
        </Typography>
      )}
    </div>
  );
}
