import React, { useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { DropDown } from 'components/common/DropDown/DropDown';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import {
  invitePatientSchema,
  resendInvitePatientSchema,
} from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErros';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { InviteService } from 'services/invite';
import { UserService } from 'services/user';

type InvitePatientProps = {
  openModal: boolean;
  onModalClose: () => void;
  onInviteResendSuccess: () => void;
  resendInviteData?: {
    id: string;
    email: string;
  };
};
type InvitePatientFormType = {
  email: string;
  name: string;
  provider: string;
};

export function InvitePatientModal({
  openModal,
  onInviteResendSuccess,
  onModalClose,
  resendInviteData,
}: InvitePatientProps) {
  const queryClient = useQueryClient();
  const inviteMutation = useMutationWithToast(InviteService.InvitePatient, {});
  const resendInviteMutation = useMutationWithToast(
    InviteService.resendPatientInvite
  );
  const { handleSubmit, control, errors, reset, setValue } =
    useFormWithErrors<InvitePatientFormType>({
      mutation: inviteMutation,
      schema: resendInviteData
        ? resendInvitePatientSchema
        : invitePatientSchema,
    });

  const getProvidersQuery = useQuery(QueryKeys.Providers.listing(), () =>
    UserService.getProviders({})
  );

  useEffect(() => {
    setValue('email', resendInviteData?.email || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendInviteData?.email]);

  const onSubmit = handleSubmit((data) => {
    if (resendInviteData) {
      resendInviteMutation.mutate(
        { name: data.name, id: resendInviteData?.id },
        {
          onSuccess: () => {
            reset();
            onInviteResendSuccess();
            queryClient.refetchQueries(QueryKeys.InvitedPatients.listings());
          },
        }
      );

      return;
    }

    inviteMutation.mutate(
      { email: data.email, name: data.name, providerId: data.provider },
      {
        onSuccess: () => {
          reset();
          onInviteResendSuccess();
          queryClient.refetchQueries(QueryKeys.InvitedPatients.listings());
        },
      }
    );
  });

  const onCloseClick = () => {
    if (inviteMutation.isLoading || resendInviteMutation.isLoading) return;
    onModalClose();
    reset();
  };

  return (
    <div>
      <Modal
        title={resendInviteData ? 'Resend Invitation' : 'Invite Patient'}
        open={openModal}
        className="w-2/5"
        closeModal={() =>
          inviteMutation.isLoading || resendInviteMutation.isLoading
            ? null
            : onModalClose()
        }>
        <div className="mt-4">
          {!resendInviteData ? (
            <Typography variant="subtitle3" className="mb-4">
              Please provide the email of the patient you want to invite.
              Patient will receive the app as well as instructions to get them
              started.
            </Typography>
          ) : (
            <Typography variant="subtitle3" className="mb-4 ">
              <div>
                Please provide the name of the patient to resend the invite. An
                invite will be sent at
                <Typography variant="subtitle3" color="primary">
                  {resendInviteData?.email}
                </Typography>
              </div>
            </Typography>
          )}
          <TextInput
            className="w-1/2 pl-2"
            type="text"
            name="name"
            control={control}
            fullWidth
            placeholder="Name"
            helperText={
              extractValidationErrors(
                'name',
                resendInviteData ? resendInviteMutation : inviteMutation,
                errors
              ).msg
            }
            error={
              extractValidationErrors(
                'name',
                resendInviteData ? resendInviteMutation : inviteMutation,
                errors
              ).hasError
            }
          />
          {!resendInviteData && (
            <TextInput
              className="mt-2 w-1/2 pl-2"
              fullWidth
              type="text"
              name="email"
              control={control}
              disabled={!!resendInviteData}
              placeholder="Email"
              helperText={
                extractValidationErrors('email', inviteMutation, errors).msg
              }
              error={
                extractValidationErrors('email', inviteMutation, errors)
                  .hasError
              }
            />
          )}
          {!resendInviteData && (
            <DropDown
              searchable
              fullWidth
              label="Select Provider"
              name="provider"
              control={control}
              theme="light"
              options={(getProvidersQuery.data?.data.items || []).map(
                (item) => ({
                  title: `${item.firstName} ${item.lastName}`,
                  value: item.id,
                })
              )}
              className="relative mt-2 w-1/2"
              helperText={
                extractValidationErrors('provider', inviteMutation, errors).msg
              }
              error={
                extractValidationErrors('provider', inviteMutation, errors)
                  .hasError
              }
            />
          )}
          {!resendInviteData ? (
            <Typography variant="subtitle3" className="">
              If patient does not receive the invitation, you may resend from
              your patient dashboard. Double-check email address before
              resubmitting.
            </Typography>
          ) : null}

          <div>
            <div className="mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                disabled={
                  inviteMutation.isLoading || resendInviteMutation.isLoading
                }
                onClick={onCloseClick}>
                Close
              </Button>{' '}
              <span className="mx-2" />
              <Button
                onClick={onSubmit}
                loading={
                  inviteMutation.isLoading || resendInviteMutation.isLoading
                }>
                {resendInviteData ? 'Resend Invite' : 'Send Invite'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
