import { APIService } from 'services/api-service';
import { OrderInfo } from 'types/orders';

import { OrderServiceNS } from './types';

export class OrderService extends APIService {
  static GET_ORDERS = `${this.API_PREFIX_URL}/orders/`;
  static getOrders = async ({
    page,
    orderType,
    orderStatus,
    createdAt,
    deliveryDate,
    patient,
    practice,
    search,
  }: OrderServiceNS.GetOrdersBody) => {
    const url = new URL(this.GET_ORDERS);
    url.searchParams.append('page', String(page));
    url.searchParams.append('size', String(10));
    if (search) {
      url.searchParams.append('search', search);
    }
    if (orderStatus) {
      url.searchParams.append('status', orderStatus);
    }
    if (orderType) {
      url.searchParams.append('order_type', orderType);
    }
    if (patient) {
      url.searchParams.append('patient_id', patient);
    }
    if (practice) {
      url.searchParams.append('practice_id', practice);
    }
    if (createdAt) {
      url.searchParams.append('created_at', createdAt);
    }
    if (deliveryDate) {
      url.searchParams.append('delivery_date', deliveryDate);
    }

    return this.get<OrderServiceNS.GetOrderInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_ORDER = `${this.API_PREFIX_URL}/orders/`;
  static getOrder = async (id: string) => {
    const url = new URL(`${this.GET_ORDERS}${id}`);

    return this.get<OrderInfo>(url.toString(), {
      withAuthToken: true,
    });
  };
}
