import { useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { removeAccessAndRefreshTokens } from 'lib/general/token';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const queryClient = useQueryClient();

  const { isLoggedIn, userProfileQuery } = useProfile();

  if (userProfileQuery.isLoading) {
    return <div />;
  }

  if (!isLoggedIn) {
    removeAccessAndRefreshTokens();
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    window.location.replace(
      `${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`
    );
  }

  return children;
}
