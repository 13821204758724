import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { FaHandHoldingMedical } from 'react-icons/fa';
import { RiStethoscopeFill, RiUserLocationLine } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { PatientsWithRefills } from 'components/dashboard/PatientsWithRefills/PatientWithRefills';
import { PatientsWithRx } from 'components/dashboard/PatientsWithRx/PatientsWithRx';
import { QueryKeys } from 'constants/query-keys';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useProfile } from 'hooks/useProfile';
import { OrderService } from 'services/order';
import { UserService } from 'services/user';

export function Dashboard() {
  const [initialRefillsLoading, setRefillsLoading] = useState(true);
  const [initialLoading, setLoading] = useState(true);

  const getUserStatsQuery = useQuery([QueryKeys.UserStats], () =>
    UserService.getUserStats()
  );

  const { profile } = useProfile();
  const navigate = useNavigate();

  const getFuturePrescriptionQuery = usePaginatedQuery(
    QueryKeys.PatientPrescriptions.listing({
      category: 'future',
    }),
    ({ pageParam = 1 }) =>
      UserService.getPatientPrescriptions({
        category: 'future',
        page: pageParam,
      }),
    {
      onSuccess: () => {
        setLoading(false);
      },
    }
  );

  const refillOrdersQuery = usePaginatedQuery(
    QueryKeys.Orders.listing({
      orderStatus: 'payment_confirmed',
      orderType: 'refill',
    }),
    ({ pageParam = 1 }) =>
      OrderService.getOrders({
        page: pageParam,
        orderType: 'refill',
        orderStatus: 'payment_confirmed',
      }),
    {
      onSuccess: () => {
        setRefillsLoading(false);
      },
    }
  );

  const handleRefillRowClick = (id: string) => {
    navigate(`../patients/${id}/overview`);
  };

  return (
    <div className="mt-10">
      <div className="flex flex-col space-y-10 px-20">
        <div className="flex w-full flex-col">
          <div className="relative grid h-24 w-[70%] grid-cols-3  gap-5 ">
            {getUserStatsQuery.isSuccess ? (
              <>
                <div className="relative flex flex-col items-end justify-center space-y-1 overflow-hidden rounded-md  bg-background-light p-4">
                  <figure className="absolute top-0 left-0 flex h-full w-full items-center">
                    <RiUserLocationLine className="relative left-5 scale-[150%] text-5xl  text-primary-contrastText opacity-25" />
                  </figure>
                  <Typography variant="h1">
                    {getUserStatsQuery.data.data.totalPracticeAdmin}
                  </Typography>
                  <Typography className="italic" variant="subtitle2">
                    PRACTICE ADMINS
                  </Typography>
                </div>
                <div className="relative flex flex-col items-end justify-center space-y-1 overflow-hidden rounded-md  bg-background-light p-4">
                  <figure className="absolute top-0 left-0 flex h-full w-full items-center">
                    <RiStethoscopeFill className="relative left-5 scale-[150%] text-5xl  text-primary-contrastText opacity-25" />
                  </figure>
                  <Typography className="" variant="h1">
                    {getUserStatsQuery.data.data.totalProviders}
                  </Typography>
                  <Typography className="italic" variant="subtitle2">
                    PROVIDERS
                  </Typography>
                </div>
                <div className="relative flex flex-col items-end justify-center space-y-1 overflow-hidden rounded-md  bg-background-light p-4">
                  <figure className="absolute top-0 left-0 flex h-full w-full items-center">
                    <FaHandHoldingMedical className="relative left-5 scale-[150%] text-5xl  text-primary-contrastText opacity-25" />
                  </figure>
                  <Typography variant="h1">
                    {getUserStatsQuery.data.data.totalPatients}
                  </Typography>
                  <Typography className="italic" variant="subtitle2">
                    PATIENTS
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <Skeleton className="h-full" />
                <Skeleton className="h-full" />
                <Skeleton className="h-full" />
              </>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-6">
            {!refillOrdersQuery.isSuccess && initialRefillsLoading ? (
              <Skeleton width={300} />
            ) : (
              <Typography variant="h4">Patients with refill orders</Typography>
            )}
            <div className="mt-3" />
            {refillOrdersQuery.isSuccess ? (
              <PatientsWithRefills
                isFetchingNextPage={refillOrdersQuery.isFetchingNextPage}
                onNextPage={refillOrdersQuery.fetchNextPage}
                patientWithRefills={refillOrdersQuery.data.pages || []}
              />
            ) : (
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col space-y-1 rounded-md bg-background-main p-5">
                  <Skeleton inline height={20} />
                  <Skeleton inline height={20} width="80%" />
                </div>
                <div className="flex flex-col space-y-1 rounded-md bg-background-main p-5">
                  <Skeleton inline height={20} />
                  <Skeleton inline height={20} width="80%" />
                </div>
              </div>
            )}
          </div>
          <div className="col-span-6">
            {!getFuturePrescriptionQuery.isSuccess && initialLoading ? (
              <Skeleton width={300} />
            ) : (
              <Typography variant="h4">
                Patients with Rx(s) requiring action
              </Typography>
            )}
            <div className="mt-3" />
            {profile && getFuturePrescriptionQuery.isSuccess ? (
              <PatientsWithRx
                onRowClick={handleRefillRowClick}
                isFetchingNextPage={
                  getFuturePrescriptionQuery.isFetchingNextPage
                }
                onNextPage={getFuturePrescriptionQuery.fetchNextPage}
                patientWithRx={getFuturePrescriptionQuery.data.pages || []}
              />
            ) : (
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col space-y-1 rounded-md bg-background-main p-5">
                  <Skeleton inline height={20} />
                  <Skeleton inline height={20} width="80%" />
                </div>
                <div className="flex flex-col space-y-1 rounded-md bg-background-main p-5">
                  <Skeleton inline height={20} />
                  <Skeleton inline height={20} width="80%" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
