import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Filter } from 'components/common/Filter/Filter';
import { SearchInput } from 'components/common/SearchInput/SearchInput';
import { ContainerSkeleton } from 'components/common/skeletons/ContainerSkeleton';
import { Table } from 'components/common/Table/Table';
import { QueryKeys } from 'constants/query-keys';
import { OrderService } from 'services/order';
import { UserService } from 'services/user';
import { OrderInfo } from 'types/orders';
import {
  formatDate,
  getReadableDate,
  splitStringWithQuotes,
} from 'utils/common';

type Option = {
  displayName: string;
  urlValue: string;
};

type FilterType = 'string' | 'date' | 'select';
type Filters = {
  type: FilterType;
  urlKey: string;
  options?: Array<Option>;
}[];

const orderStatuses = [
  { displayName: 'Payment Confirmed', urlValue: 'payment_confirmed' },
  { displayName: 'Order Confirmed', urlValue: 'order_confirmed' },
  { displayName: 'In Progress', urlValue: 'in_progress' },
  { displayName: 'Out For Delivery', urlValue: 'out_for_delivery' },
  { displayName: 'Completed', urlValue: 'completed' },
  { displayName: 'Confirmed Receipt', urlValue: 'confirmed_receipt' },
  { displayName: 'Error', urlValue: 'error' },
];

const orderTypes = [
  { displayName: 'Rx(s)', urlValue: 'new' },
  { displayName: 'Refill', urlValue: 'refill' },
];

export function Orders() {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleRowClick = (orderId: string, patientId: string) => {
    navigate(`${orderId}/overview`, { state: { patientId } });
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getPatientsQuery = useQuery(QueryKeys.Patients.listings(), () =>
    UserService.getPatients({})
  );

  function getOrderType(order: OrderInfo) {
    if (order.orderType === 'new') {
      return 'Rx(s)';
    }
    if (order.orderType === 'refill') {
      return 'Refill';
    }

    return 'NA';
  }

  const filters: Filters = [
    {
      type: 'string',
      options: orderStatuses,
      urlKey: 'order_status',
    },
    {
      type: 'string',
      options: orderTypes,
      urlKey: 'order_type',
    },
    {
      type: 'string',
      options: getPatientsQuery.data?.data.items.map((patient) => ({
        urlValue: patient.id,
        displayName: `${patient.firstName} ${patient.lastName}`,
      })),
      urlKey: 'patient',
    },
    {
      type: 'date',
      options: [],
      urlKey: 'delivery_date',
    },
    {
      type: 'date',
      options: [],
      urlKey: 'created_at',
    },
  ];

  const buildParams = () => {
    const query = searchParams.get('Query') || '';

    return splitStringWithQuotes(query).reduce(
      (acc: { [x: string]: string }, filter) => {
        const [queryKey, queryValue] = filter.split(':');
        if (!queryValue) return acc;
        const normalisedValue = queryValue.replace(/"/g, '');
        const currentFilter = filters.find(
          (fil) => `${fil.urlKey.toLowerCase()}` === queryKey.toLowerCase()
        );
        const option =
          currentFilter?.type === 'date'
            ? { urlValue: formatDate(new Date(normalisedValue)) }
            : currentFilter?.options?.find(
                (opt) =>
                  opt.displayName.toLowerCase() ===
                  normalisedValue.toLowerCase()
              );
        acc[queryKey] = option?.urlValue || '';

        return camelcaseKeys(acc);
      },
      {}
    );
  };
  const ordersQuery = useQuery(
    QueryKeys.Orders.listing({ page, search, ...buildParams() }),
    () => OrderService.getOrders({ page, search, ...buildParams() })
  );

  return (
    <div className="mt-10">
      <div className="mb-4 flex items-center space-x-3">
        {getPatientsQuery.isSuccess ? (
          <>
            <Filter filters={filters} onFilterChange={() => setPage(1)} />
            <SearchInput
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </>
        ) : (
          <>
            <ContainerSkeleton className="h-11 w-[80%] animate-pulse rounded-md bg-background-light" />
            <ContainerSkeleton className="h-11 w-[20%] animate-pulse rounded-md bg-background-light" />
          </>
        )}
      </div>
      <Table
        loading={ordersQuery.isLoading}
        rowsData={ordersQuery?.data?.data.items || []}
        columns={[
          {
            title: 'Order Id',
            render: (rowData) => rowData.id,
          },
          {
            title: 'Created Date',
            render: (rowData) => getReadableDate(rowData.createdAt),
          },
          {
            title: 'Status',
            render: (rowData) => rowData.status.split('_').join(' '),
            classNames: 'first-letter:capitalize',
          },
          {
            title: 'Shipping Type',
            render: (rowData) => rowData.preferredShipping.name || 'NA',
            classNames: 'capitalize',
          },
          {
            title: 'Order Type',
            render: (rowData) => getOrderType(rowData),
          },
          {
            title: 'Tracking Id',
            render: (rowData) => rowData.trackingNumber || 'NA',
          },
          {
            title: 'Carrier',
            render: (rowData) => rowData.carrier || 'NA',
          },
          {
            title: 'Delivery Date',
            render: (rowData) => getReadableDate(rowData.deliveryDate) || 'NA',
          },
        ]}
        onRowClick={(rowData) => handleRowClick(rowData.id, rowData.patientId)}
        noDataMessage="You currently have no orders"
        pagination
        onPageChange={onPageChange}
        page={page}
        totalRecords={ordersQuery?.data?.data.total || 0}
        recordsPerPage={10}
      />
    </div>
  );
}
