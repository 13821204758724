// eslint-disable-next-line import/extensions
import LogoGreen from 'assets/images/LogoGreen(OnLeft).svg';
import { AvatarDropdown } from 'components/general/AvatarDropdown/AvatarDropdown';
import { useProfile } from 'hooks/useProfile';

export function Header() {
  const { profile } = useProfile();

  return (
    <div className="w-full  rounded bg-background-main py-2  ">
      <div className="flex justify-between px-8">
        <img
          className="h-10 rounded"
          src={profile?.practice?.logoUrl || LogoGreen}
          alt={profile?.practice?.name}
        />
        <div className="flex items-center space-x-4">
          <AvatarDropdown />
        </div>
      </div>
    </div>
  );
}
