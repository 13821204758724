import React, { useEffect, useState } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { useDebounce } from 'hooks/useDebounce';

import { TextInput } from '../Input/TextInput';

type SearchInputProps = {
  onSearch: (value: string) => void;
  id?: string;
  placeholderText?: string;
};

export function SearchInput({
  onSearch,
  id,
  placeholderText,
}: SearchInputProps) {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    onSearch(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div id={id} className=" relative flex items-center">
      <div
        id={id}
        className="pointer-events-none absolute inset-y-0  left-0 ml-2 flex  items-center text-background-contrastText">
        <MagnifyingGlassIcon height={16} />
      </div>
      <TextInput
        id={id}
        value={value}
        type="text"
        placeholder={placeholderText || 'Search'}
        onChange={(text) => setValue(text)}
        className=" pl-7 "
        disableHelperText
      />
    </div>
  );
}
