import React, { useEffect, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

export type PaginationProps = {
  page: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  recordsPerPage: number;
};

export function Pagination({
  page = 1,
  totalRecords,
  onPageChange,
  recordsPerPage,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    onPageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <nav
      className="mr-2 flex justify-between text-background-contrastText"
      aria-label="Table navigation">
      <span className="text-sm font-normal " />
      <span className="flex  ">
        <ChevronLeftIcon
          height={18}
          className="cursor-pointer"
          onClick={() => {
            if (currentPage > 1) setCurrentPage((prev) => prev - 1);
          }}
        />{' '}
        <p className="mx-2 text-base">
          Page {page} of {Math.ceil(totalRecords / recordsPerPage)}
        </p>{' '}
        <ChevronRightIcon
          height={18}
          className="cursor-pointer"
          onClick={() => {
            if (currentPage < totalRecords / recordsPerPage) {
              setCurrentPage((prev) => prev + 1);
            }
          }}
        />
      </span>
    </nav>
  );
}
