import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { BillingService } from 'services/billing';
import { MedicationService } from 'services/medication-prescription';
import {
  PATIENT_PRESCRIPTION_CATEGORIES,
  PATIENT_PRESCRIPTION_STATUS as PRESCRIPTION_STATUS,
} from 'types/common';
import { SUBSCRIPTION_CHANGE_CODE } from 'types/subscription/types';

export const useSubscriptionState = () => {
  const { id } = useParams();

  const prescriptionsQuery = useQuery(
    QueryKeys.PatientPrescription.listing({ id }),
    () =>
      MedicationService.getPatientPrescription({
        id: id || '',
      }),
    { select: (data) => data.data }
  );

  const billingPlansQuery = useQuery(QueryKeys.BillingPlans.listing(), () =>
    BillingService.getBillingPlans(id as string)
  );

  if (prescriptionsQuery.status !== 'success') {
    return null;
  }
  const currentPrescriptionsData = prescriptionsQuery?.data?.filter(
    (item) => item.category === PATIENT_PRESCRIPTION_CATEGORIES.Current
  );
  const futurePrescriptionsData = prescriptionsQuery?.data?.filter(
    (item) => item.category === PATIENT_PRESCRIPTION_CATEGORIES.Future
  );

  const futurePendingPrescriptionsData = futurePrescriptionsData.filter(
    (item) =>
      item.status === PRESCRIPTION_STATUS.PendingReview &&
      item.category === PATIENT_PRESCRIPTION_CATEGORIES.Future
  );

  const billingPlans = billingPlansQuery.data?.data;

  const conditions = {
    get canInitiateFirstSubscription() {
      return (
        !this.canUpdateSubscription && futurePendingPrescriptionsData.length > 0
      );
    },

    get canUpdateSubscription() {
      return (
        currentPrescriptionsData.length > 0 &&
        futurePendingPrescriptionsData.length > 0
      );
    },

    get isNonMedicationPayment() {
      return (
        currentPrescriptionsData.length > 0 &&
        futurePendingPrescriptionsData.length > 0 &&
        billingPlans?.subscriptionProducts.length
      );
    },

    get subscriptionChangeCode() {
      if (!billingPlans) return null;
      if (this.canInitiateFirstSubscription) return null;
      if (billingPlans?.expectedCount === billingPlans?.currentCount) {
        return SUBSCRIPTION_CHANGE_CODE.NoChange;
      }
      if (billingPlans?.expectedCount > billingPlans?.currentCount) {
        return SUBSCRIPTION_CHANGE_CODE.Upgrade;
      }
      if (billingPlans?.expectedCount < billingPlans?.currentCount) {
        return SUBSCRIPTION_CHANGE_CODE.Downgrade;
      }

      return null;
    },

    get isProrationApplicable() {
      return (
        this.canUpdateSubscription &&
        !!billingPlans?.subscriptionProducts?.length
      );
    },
  };

  return conditions;
};
