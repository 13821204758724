import React, { Dispatch, SetStateAction, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Modal } from 'components/common/Modal/Modal';
import { Stepper } from 'components/common/Stepper/Stepper';
import { StepTab } from 'components/common/Stepper/StepTab';
import { CardDetailsForm } from 'components/subscription/CardDetailsForm/CardDetailsFrom';
import { SelectPaymentPlan } from 'components/subscription/SelectPaymentPlan/SelectPaymentPlan';
import { UpdateSubscription } from 'components/subscription/UpdateSubscription/UpdateSubscription';
import { QueryKeys } from 'constants/query-keys';
import { useSubscriptionState } from 'hooks/usePatientSubscriptionState';
import { UserService } from 'services/user';

interface PatientSubscriptionModalProps {
  showPaymentModal: boolean;
  setShowPaymentModal: Dispatch<SetStateAction<boolean>>;
}

export function PatientSubscriptionModal({
  showPaymentModal,
  setShowPaymentModal,
}: PatientSubscriptionModalProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const { id } = useParams();
  const subscriptionState = useSubscriptionState();

  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id || ''),
    () => UserService.getPatientDetails(id || ''),
    {
      enabled: !!id,
    }
  );
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    clientSecret: '',
    amount: 0,
  });

  return (
    <SkeletonTheme>
      <Modal
        className="!h-[80vh] w-1/4 overflow-y-auto"
        open={showPaymentModal}
        closeModal={(open) => {
          setShowPaymentModal(open);
        }}>
        <div className="-mt-2 flex ">
          {subscriptionState?.canUpdateSubscription ? (
            <UpdateSubscription
              onSubscriptionUpdate={() => setShowPaymentModal(false)}
            />
          ) : subscriptionState?.canInitiateFirstSubscription ? (
            <Stepper
              currentStep={currentStep}
              steps={['Payment Plans', 'Credit Card Details']}>
              <StepTab step={0}>
                {/* subscription plans */}
                <SelectPaymentPlan
                  showHeadingComponent
                  onSubscriptionSuccess={(obj) => {
                    setSubscriptionDetails(obj);
                    setCurrentStep(1);
                  }}
                />
              </StepTab>
              <StepTab step={1}>
                <CardDetailsForm
                  clientSecret={subscriptionDetails.clientSecret}
                  patientDetails={{
                    name: patientDetailsQuery.data?.data.firstName || '',
                    email: patientDetailsQuery.data?.data.email || '',
                  }}
                  amount={subscriptionDetails.amount}
                  onCardAdded={() => {
                    setShowPaymentModal(false);
                  }}
                  onModalClose={() => {
                    setShowPaymentModal(false);
                  }}
                />
              </StepTab>
            </Stepper>
          ) : null}
        </div>
      </Modal>
    </SkeletonTheme>
  );
}
