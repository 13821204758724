import { APIService } from 'services/api-service';

import { AuthenticationServiceNS } from './types';

export class AuthenticationService extends APIService {
  static TOKEN_LOGIN_URL = `${this.API_PREFIX_URL}/auth/token/get-tokens`;
  static tokenLogin = ({
    loginToken,
  }: AuthenticationServiceNS.TokenLoginBody) =>
    this.get<AuthenticationServiceNS.TokenLoginResponse>(
      `${AuthenticationService.TOKEN_LOGIN_URL}?login_token=${loginToken}`
    );

  static LOGIN_URL = `${this.API_PREFIX_URL}/auth/login`;
  static login = ({ email, password }: AuthenticationServiceNS.LoginBody) =>
    this.post<AuthenticationServiceNS.LoginResponse>(
      AuthenticationService.LOGIN_URL,
      {
        email,
        password,
      }
    );
}
