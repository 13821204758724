import React, { useCallback } from 'react';

import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { getReadableDate } from 'utils/common';

type RefillsPatientProps = {
  isFetchingNextPage: boolean;
  onNextPage: () => void;
  patientWithRefills: {
    id: string;
    patientFirstName: string;
    patientLastName: string;
    patientId: string;
    createdAt: string;
  }[];
};

export function PatientsWithRefills({
  patientWithRefills,
  onNextPage,
  isFetchingNextPage,
}: RefillsPatientProps) {
  const onPatientWithRxScroll = useCallback(
    (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
        onNextPage();
      }
    },
    [onNextPage]
  );

  return (
    <div>
      {patientWithRefills.length ? (
        <div className="relative rounded-md">
          <ul
            onScroll={onPatientWithRxScroll}
            className="dropdown-scrollbar flex max-h-[20rem] flex-col overflow-auto pr-2 pt-0.5">
            {patientWithRefills.map((item) => (
              <li>
                <button
                  type="button"
                  className="flex w-[32rem] items-center rounded-lg bg-background-main p-5  py-4"
                  key={item.id}>
                  <Typography
                    className="max-w-lg text-background-contrastText"
                    variant="subtitle2">
                    <span>
                      Refill order raised by patient{' '}
                      <span className="text-primary-light">{`${item.patientFirstName}  ${item.patientLastName}`}</span>{' '}
                      on {getReadableDate(item.createdAt)}.
                    </span>{' '}
                  </Typography>
                </button>
              </li>
            ))}
          </ul>
          {isFetchingNextPage ? (
            <li className="mt-2 flex justify-center">
              <LoadingSpinner className="text-primary-main" />
            </li>
          ) : null}
        </div>
      ) : (
        <Typography variant="subtitle2" color="textMuted">
          No refill orders.
        </Typography>
      )}
    </div>
  );
}
