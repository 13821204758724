import React, { useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { SHIPPING_TYPE, ShipmentType } from 'types/common';

import 'react-loading-skeleton/dist/skeleton.css';

export type SelectedShipmentTypeDetails = {
  type: SHIPPING_TYPE.Expedited | SHIPPING_TYPE.Standard;
  priceId: string;
  amount: number;
};

type ShipmentTypeSelectiondivProps = {
  onShipmentChange: (productId: string, product?: ShipmentType) => void;
  shipmentTypesData: ShipmentType[];
  selectedShipmentTypeId: string;
  isLoading?: boolean;
  prefferedShipping: string;
};

export function ShipmentTypeSelectionCard({
  onShipmentChange,
  shipmentTypesData,
  selectedShipmentTypeId,
  isLoading = false,
  prefferedShipping,
}: ShipmentTypeSelectiondivProps) {
  useEffect(() => {
    if (!selectedShipmentTypeId) {
      const shipmentObject = shipmentTypesData.find((item) =>
        item.name.includes(prefferedShipping)
      );

      if (shipmentObject) {
        onShipmentChange(shipmentObject.id, shipmentObject);
      } else {
        onShipmentChange(shipmentTypesData[0].id, shipmentTypesData[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentTypesData]);

  return (
    <div className="mt-3">
      <div className="mt-2 mb-2 rounded-lg border border-gray-200 bg-white p-2 shadow-sm">
        <div className="mb-2">
          <Typography>Shipment Type</Typography>
        </div>

        {isLoading ? (
          <>
            <div className=" mb-1 flex justify-between">
              <div className="flex">
                {' '}
                <Skeleton circle width={15} />{' '}
                <Skeleton count={1} width={130} className="ml-2" />
              </div>{' '}
              <Skeleton width={20} className="ml-3" />
            </div>
            <div className=" flex justify-between">
              <div className="flex">
                {' '}
                <Skeleton circle width={15} />{' '}
                <Skeleton count={1} width={130} className="ml-2" />
              </div>{' '}
              <Skeleton width={20} className="ml-3" />
            </div>
          </>
        ) : shipmentTypesData.length > 0 ? (
          shipmentTypesData.map((item) => (
            // eslint-disable-next-line react/jsx-indent
            <div key={item.name} className="mb-2 flex justify-between">
              <div>
                <RadioButton
                  id={item.id}
                  checked={selectedShipmentTypeId === item.id}
                  label={item.name}
                  onChange={() => {
                    onShipmentChange(item.id, item);
                  }}
                />
              </div>
              <Typography variant="body2" color="primary">
                ${item.price}
              </Typography>
            </div>
          ))
        ) : null}
      </div>
    </div>
  );
}
