import { useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { BillingService } from 'services/billing';
import { Product, Proration } from 'types/payment/types';

const UPDATE_TIME_AFTER_MINUTES = 1000 * 60 * 2;
export const useProratedPrices = ({
  products,
  enabled,
}: {
  products: Product[];
  enabled: boolean;
}) => {
  const { current: time } = useRef(Math.floor(Date.now() / 1000));
  const { id } = useParams();
  const calculateProrationQuery = useQuery({
    queryKey: QueryKeys.ProrationAmount.listing({
      priceIds: products.map((p) => p.stripePriceId),
      prorationTime: time,
    }),
    queryFn: () =>
      Promise.all(
        products.map((prod) =>
          BillingService.calculateProrationAmount({
            priceId: prod?.stripePriceId || '',
            prorationDate: time,
            id: id || '',
          }).then((res) => ({
            ...res.data,
            priceId: prod.stripePriceId,
            productId: prod.id,
          }))
        )
      ),
    enabled,

    cacheTime: UPDATE_TIME_AFTER_MINUTES,
    select: (data) =>
      data.reduce<{ [x: string]: Proration }>(
        (acc, proration) => ({ ...acc, [proration.productId]: proration }),
        {}
      ),
  });

  return calculateProrationQuery;
};
