import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { TableSkeletonProps } from './types';

export function TableSkeleton<TItem>({
  recordsPerPage,
  columns,
}: TableSkeletonProps<TItem>) {
  return (
    <>
      {[...Array(recordsPerPage)].map(() => (
        <tr className="bg-background-main">
          {columns.map((item) => (
            <td className={classNames('px-6  py-3 ', item.skeletonClassNames)}>
              <Skeleton containerClassName="w-full" className=" h-4" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
