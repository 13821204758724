import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { SearchInput } from 'components/common/SearchInput/SearchInput';
import { Table } from 'components/common/Table/Table';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';
import { UserServiceNS } from 'services/user/types';
import { getAddressesString, getReadableDate } from 'utils/common';

export function ActivePatients() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const getPatientsQuery = useQuery(
    QueryKeys.Patients.listing({ page, search }),
    () => UserService.getPatients({ page, search })
  );

  useEffect(() => {
    setPage(1);
  }, [search]);

  const handleRowClick = (item: UserServiceNS.PatientInfo) => {
    navigate(`../${item.id}/overview`);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div>
      <div className="grid">
        <span className="mb-3 flex  items-center justify-end text-base ">
          <SearchInput
            onSearch={(value) => {
              setSearch(value);
            }}
          />
        </span>

        <Table
          loading={getPatientsQuery?.isLoading}
          rowsData={getPatientsQuery.data?.data?.items || []}
          columns={[
            {
              title: 'Id',
              render: (rowData) => rowData.id,
            },
            {
              title: 'Name',
              render: (rowData) => `${rowData.firstName} ${rowData.lastName}`,
            },
            {
              title: 'Email',
              render: (rowData) => rowData.email,
            },
            {
              title: 'Address',
              render: (rowData) => getAddressesString(rowData.practiceAddress),
            },
            {
              title: 'Completed Visits',
              render: (rowData) => rowData.patientCompletedVisits || 0,
              classNames: 'text-center',
              skeletonClassNames: 'flex justify-center',
            },
            {
              title: 'Doctor Name',
              render: (rowData) => rowData.patientsProvider.name,
            },
            {
              title: 'Practice Name',
              render: (rowData) => rowData.practice.name,
            },
            {
              title: 'Joining Date',
              render: (rowData) => getReadableDate(rowData.createdAt),
              classNames: 'text-end',
              skeletonClassNames: 'flex justify-end',
            },
          ]}
          onRowClick={handleRowClick}
          noDataMessage="You currently have no active patients"
          pagination
          onPageChange={onPageChange}
          page={page}
          totalRecords={getPatientsQuery.data?.data?.total || 0}
          recordsPerPage={10}
          isSticky
        />
      </div>
    </div>
  );
}
