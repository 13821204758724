/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';

import { Typography } from 'components/common/Typography/Typography';
import { getPriceInTwoDecimals } from 'utils/price-calculation';

type CouponProps = {
  discountedAmount: number;
  code: string;
  id: string;
  handleCoupon: (id: string) => void;
};
export function Coupons({
  discountedAmount = 20,
  code = 'PRIME20',
  id = 'sdefregre',
  handleCoupon,
}: CouponProps) {
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  return (
    <div className="mt-3">
      <div className="mt-2 mb-2 rounded-lg border border-gray-200 bg-white p-2 shadow-sm">
        <div>
          {isCouponApplied ? (
            <Typography variant="subtitle3" className="mb-1">
              You have saved &nbsp;
              <Typography
                color="primary"
                style={{ fontWeight: 'bold' }}
                variant="subtitle3">
                ${getPriceInTwoDecimals(discountedAmount.toString())}
              </Typography>
              &nbsp; with this code.
            </Typography>
          ) : (
            <Typography variant="subtitle3" className="mb-1">
              Save&nbsp;
              <Typography
                color="primary"
                style={{ fontWeight: 'bold' }}
                variant="subtitle3">
                ${getPriceInTwoDecimals(discountedAmount.toString())}
              </Typography>
              &nbsp;more on this subscription.
            </Typography>
          )}
          <Typography variant="subtitle3" className="mb-2">
            {isCouponApplied ? 'Coupon Applied' : `Code: ${code}`}
          </Typography>

          <div
            style={{
              width: '100%',
              borderBottomWidth: 1,
              borderBottomLeftRadius: 2,
              borderBottomRightRadius: 2,
              borderBottomColor: 'lightgray',
            }}
          />

          <div
            className="flex cursor-pointer justify-center pt-2"
            onClick={() => {
              setIsCouponApplied(!isCouponApplied);
              handleCoupon(!isCouponApplied ? id : '');
            }}>
            <Typography color={isCouponApplied ? 'error' : 'primary'}>
              {isCouponApplied ? 'Remove' : 'Apply'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
