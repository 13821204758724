import { APIService } from 'services/api-service';

import { InviteServiceNS } from './types';

export class InviteService extends APIService {
  static DELETE_INVITE = `${this.API_PREFIX_URL}/invites`;
  static deleteProviderInvite = async ({
    id,
  }: InviteServiceNS.DeleteInviteProviderBody) =>
    this.delete(
      `${InviteService.DELETE_INVITE}/${id}`,

      {
        withAuthToken: true,
      }
    );

  static PROVIDER_INVITE_URL = `${this.API_PREFIX_URL}/invites/provider`;
  static InviteProvider = ({
    email,
    name,
    practice,
    addressId,
  }: InviteServiceNS.ProviderInviteBody) =>
    this.post(
      InviteService.PROVIDER_INVITE_URL,
      {
        email,
        name,
        practice_Id: practice,
        practice_address_id: addressId,
      },
      {
        withAuthToken: true,
      }
    );

  static RESEND_INVITE = `${this.API_PREFIX_URL}/invites/resend`;
  static resendProviderInvite = async ({
    id,
    name,
  }: InviteServiceNS.ResendInviteProviderBody) =>
    this.put(
      `${InviteService.RESEND_INVITE}/${id}`,
      { name },
      {
        withAuthToken: true,
      }
    );
  static GET_INVITED_PROVIDERS = `${this.API_PREFIX_URL}/invites/`;
  static getInvitedProviders = async ({
    page,
  }: InviteServiceNS.GetInvitedProvidersBody) => {
    const url = new URL(this.GET_INVITED_PROVIDERS);
    url.searchParams.append('user_type', 'Provider');
    url.searchParams.append('page', String(page));
    url.searchParams.append('size', '10');

    return this.get<InviteServiceNS.GetInvitedProvidersResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static PATIENT_INVITE_URL = `${this.API_PREFIX_URL}/invites/patient`;
  static InvitePatient = ({
    email,
    name,
    providerId,
  }: InviteServiceNS.PatientInviteBody) =>
    this.post(
      InviteService.PATIENT_INVITE_URL,
      {
        email,
        name,
        provider_id: providerId,
      },
      {
        withAuthToken: true,
      }
    );

  static GET_INVITED_PATIENTS = `${this.API_PREFIX_URL}/invites/`;
  static getInvitedPatients = async ({
    page,
  }: InviteServiceNS.GetInvitedPatientsBody) => {
    const url = new URL(this.GET_INVITED_PATIENTS);
    url.searchParams.append('user_type', 'Patient');
    url.searchParams.append('page', String(page));
    url.searchParams.append('size', '10');

    return this.get<InviteServiceNS.GetInvitedPatientsResponse>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static RESEND_PATIENT_INVITE = `${this.API_PREFIX_URL}/invites/resend`;
  static resendPatientInvite = async ({
    id,
    name,
  }: InviteServiceNS.ResendInvitePatientBody) =>
    this.put(
      `${InviteService.RESEND_PATIENT_INVITE}/${id}`,
      { name },
      {
        withAuthToken: true,
      }
    );

  static DELETE_PATIENT_INVITE = `${this.API_PREFIX_URL}/invites`;
  static deletePatientInvite = async ({
    id,
  }: InviteServiceNS.DeleteInvitePatientBody) =>
    this.delete(
      `${InviteService.DELETE_PATIENT_INVITE}/${id}`,

      {
        withAuthToken: true,
      }
    );
}
