import { useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useProratedPrices } from 'hooks/usePatientProratedPrices';
import { useSubscriptionState } from 'hooks/usePatientSubscriptionState';
import { BillingService } from 'services/billing';
import { UserService } from 'services/user';
import { BILLING_CYCLES } from 'types/payment/types';
import { getPriceInTwoDecimals } from 'utils/price-calculation';

import { SubscriptionProducts } from '../SubscriptionProducts/SubscriptionProducts';
import { Coupons } from './Coupons/Coupons';
import { IndividualProductsCard } from './IndividualProductsCard/IndividualProductsCard';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { SubscriptionProductPricingCard } from './PaymentTypeCard/PaymentTypeCard';
import { ShipmentTypeSelectionCard } from './ShipmentTypeSelectionCard/ShipmentTypeSelectionCard';

type PriceIDList = { price: string };

type SelectPaymentPlanFormProps = {
  showHeadingComponent?: boolean;
  onSubscriptionSuccess: ({
    clientSecret,
    amount,
  }: {
    clientSecret: string;
    amount: number;
  }) => void;
};

export function SelectPaymentPlan({
  onSubscriptionSuccess,
  showHeadingComponent,
}: SelectPaymentPlanFormProps) {
  const totalRef = useRef<{
    total: number;
    totalInDecimal: string;
    discountedAmount: number;
    creditUsed: number;
  }>({
    total: 0,
    totalInDecimal: '',
    discountedAmount: 0,
    creditUsed: 0,
  });

  const subscriptionState = useSubscriptionState();
  const { id } = useParams();
  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id || ''),
    () => UserService.getPatientDetails(id || '')
  );
  const [selectedSubscriptionPlanId, setSelectedSubscriptionPlanId] =
    useState('');
  const [selectedShipmentTypeId, setSelectedShipmentTypeId] = useState('');
  const [couponCode, setCouponCode] = useState('');

  const billingPlansQuery = useQuery(QueryKeys.BillingPlans.listing(), () =>
    BillingService.getBillingPlans(id as string)
  );

  const creditsQuery = useQuery(
    [QueryKeys.Balance],
    () => BillingService.getCreditBalance(id || ''),
    { select: (d) => d.data }
  );

  const calculateProrationQuery = useProratedPrices({
    enabled: !!subscriptionState?.isProrationApplicable,
    products: billingPlansQuery.data?.data.subscriptionProducts || [],
  });

  const createSubscriptionMutation = useMutationWithToast(
    BillingService.createSubscription
  );
  const updateSubscriptionMutation = useMutationWithToast(
    BillingService.updateSubscription
  );

  if (billingPlansQuery.isLoading || !billingPlansQuery.data) return null;

  const subscriptionPlans = billingPlansQuery.data?.data.subscriptionProducts;
  const individualProducts =
    billingPlansQuery.data?.data.rxsNotCoveredUnderSubscription;
  const shipmentTypeProducts = billingPlansQuery.data?.data.shippingProduct;
  const subscriptionProducts =
    billingPlansQuery.data.data.rxsCoveredUnderSubscription;

  const selectedSubscriptionPlan = subscriptionPlans?.find(
    (plan) => plan.id === selectedSubscriptionPlanId
  );
  const selectedShipmentPlan = shipmentTypeProducts?.find(
    (plan) => plan.id === selectedShipmentTypeId
  );

  const handlePaymentPlanSubmit = async () => {
    if (!selectedShipmentPlan) return;

    const subscriptionBody: Partial<
      Record<'recurring' | 'oneTime', PriceIDList>
    > & { coupon?: string } = {
      recurring: { price: '' },
      oneTime: { price: '' },
      coupon: '',
    };

    subscriptionBody.recurring = { price: '' };
    subscriptionBody.oneTime = { price: '' };

    // Add subscription product in recurring payment list.
    if (subscriptionPlans?.length > 0 && selectedSubscriptionPlan) {
      subscriptionBody.recurring.price =
        selectedSubscriptionPlan?.stripePriceId;
    }
    if (!subscriptionBody.recurring.price) delete subscriptionBody.recurring;

    // Add shipment product to one time payment list.
    if (shipmentTypeProducts.length > 0) {
      subscriptionBody.oneTime.price = selectedShipmentPlan?.stripePriceId;
    }
    if (couponCode) {
      subscriptionBody.coupon = couponCode;
    } else delete subscriptionBody.coupon;

    await (subscriptionState?.canUpdateSubscription
      ? updateSubscriptionMutation
      : createSubscriptionMutation
    ).mutate(
      { ...subscriptionBody, id },
      {
        onSuccess: ({ data }) => {
          onSubscriptionSuccess({
            clientSecret: data.clientSecret,
            amount: totalRef.current.total - totalRef.current.discountedAmount,
          });
        },
      }
    );
  };

  const getDiscountedAmount = () => {
    if (billingPlansQuery.data.data.coupons.length) {
      const subscriptionObject = subscriptionPlans?.find(
        (plan) => plan.id === selectedSubscriptionPlanId
      );

      return (
        (Number(subscriptionObject?.amount || 0) *
          billingPlansQuery.data.data.coupons[0].percentOff) /
        100
      );
    }

    return 0;
  };

  const getTotal = () => {
    const totalPrice = [
      // Addition of all individual product prices.
      individualProducts?.reduce(
        (acc, item) => acc + Number(item?.product?.amount || 0),
        0
      ),
      Number(selectedShipmentPlan?.amount) || 0,
      subscriptionState?.isProrationApplicable
        ? calculateProrationQuery.data?.[selectedSubscriptionPlanId]?.total || 0
        : Number(selectedSubscriptionPlan?.amount || 0),
    ].reduce((acc, val) => acc + val, 0);

    const discountedAmount = couponCode ? getDiscountedAmount() : 0;
    totalRef.current.total = totalPrice;
    totalRef.current.discountedAmount = discountedAmount;
    totalRef.current.creditUsed =
      Math.abs(Number(creditsQuery.data?.balance)) > 0 && totalPrice > 0
        ? Math.abs(Number(creditsQuery.data?.balance)) >
          totalPrice - discountedAmount
          ? totalPrice - discountedAmount || 0
          : Math.abs(Number(creditsQuery.data?.balance))
        : 0;
    totalRef.current.totalInDecimal = getPriceInTwoDecimals(
      totalPrice - discountedAmount - totalRef.current.creditUsed > 0
        ? totalPrice - discountedAmount - totalRef.current.creditUsed
        : 0
    );

    return totalRef.current;
  };

  // Calling get total on every render so that the price in the ref stays up to date all the time.
  getTotal();

  /**
   * In some cases the proration amount might be greater than the
   * total of the price the patient need to pay.
   * In those cases total price would be in negative
   * And payment might not be required.
   */
  const isPaymentRequired = totalRef.current.total > 0;

  /**
   * Subscription upgrade or downgrade is not allowed for male.
   * Though technically it is possible to upgrade or downgrade the subscription for male as well.
   */
  // userProfileDetailsQuery.data?.patientDetails.gender === GENDER.Female;

  const isLoading =
    (subscriptionState?.isProrationApplicable &&
      calculateProrationQuery.isLoading) ||
    billingPlansQuery.isLoading;

  const SubscriptionItems = (
    <SubscriptionProductPricingCard
      isLoading={isLoading}
      selectedPlanId={selectedSubscriptionPlanId}
      preferredPlan={
        patientDetailsQuery.data?.data.patientSubscriptionMeta
          .preferredBillingType || 'annualy'
      }
      subscriptionProducts={
        subscriptionPlans?.map((plan) => ({
          id: plan.id,
          name: plan.name || '',
          priceInDecimal: subscriptionState?.isProrationApplicable
            ? getPriceInTwoDecimals(
                /**
                 * Price should always be in positive.
                 * In case of proration the price might go in negative amount.
                 * But instead of negative amount we need to show 0.
                 */
                (calculateProrationQuery.data?.[plan.id]?.total || 0) <= 0
                  ? 0
                  : calculateProrationQuery.data?.[plan.id]?.total || 0
              )
            : getPriceInTwoDecimals(plan.amount),
          recurringInterval: plan.billingCycle || BILLING_CYCLES.Yearly,
        })) || []
      }
      updateSelectedPlan={(planId) => {
        setSelectedSubscriptionPlanId(
          subscriptionPlans?.find((prod) => prod.id === planId)?.id || ''
        );
      }}
    />
  );

  return (
    <div
      className={`mt-3 ${
        createSubscriptionMutation.isLoading ||
        updateSubscriptionMutation.isLoading
          ? 'pointer-events-none'
          : 'pointer-events-auto'
      }`}>
      {showHeadingComponent && subscriptionPlans.length ? (
        <div className="flex">
          <Typography className="mb-3" variant="h4">
            <span>
              <span> What is your</span>
              <span className="text-primary-light">
                {' '}
                preffered payment plan?
              </span>
            </span>
          </Typography>
        </div>
      ) : null}

      {subscriptionPlans.length ? SubscriptionItems : null}

      {subscriptionProducts.length > 0 ? (
        <SubscriptionProducts
          isLoading={isLoading}
          subscriptionProducts={subscriptionProducts}
        />
      ) : null}

      {!isPaymentRequired ? (
        <div className="mt-2 mb-2 rounded-lg border border-gray-200 bg-white p-2">
          <Typography variant="body2">
            You will received a credit of $
            {getPriceInTwoDecimals(Math.abs(totalRef.current.total))} due to
            change in your subscription’s billing cycle or plan. This credit
            balance wil be utilised for your current or next payment.
          </Typography>
        </div>
      ) : null}

      {billingPlansQuery.data.data.coupons.length &&
      isPaymentRequired &&
      subscriptionState?.canInitiateFirstSubscription ? (
        <Coupons
          discountedAmount={getDiscountedAmount()}
          handleCoupon={(couponId) => setCouponCode(couponId)}
          code={billingPlansQuery.data.data?.coupons[0]?.couponName}
          id={billingPlansQuery.data.data?.coupons[0]?.id}
        />
      ) : null}

      {individualProducts?.length > 0 ? (
        <IndividualProductsCard
          isLoading={isLoading}
          individualProducts={individualProducts}
        />
      ) : null}

      {shipmentTypeProducts?.length > 0 ? (
        <ShipmentTypeSelectionCard
          isLoading={isLoading}
          selectedShipmentTypeId={selectedShipmentTypeId}
          prefferedShipping={
            patientDetailsQuery.data?.data.patientSubscriptionMeta
              .preferredBillingType || 'standard'
          }
          onShipmentChange={(productId) => setSelectedShipmentTypeId(productId)}
          shipmentTypesData={shipmentTypeProducts.map((item) => {
            const productData = JSON.parse(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item?.productMetadata as any) || ''
            );

            return {
              id: item.id,
              name: productData.shipping_type?.replace('Shipping', '') || '',
              price: getPriceInTwoDecimals(item.amount),
              priceId: item.stripePriceId,
              unitAmount: Number(item.amount),
            };
          })}
        />
      ) : null}

      <div className="mt-2 mb-2  rounded-lg border  border-gray-200 bg-white p-2">
        {isLoading ? (
          <div className=" flex w-full justify-between">
            <Skeleton count={1} width={130} height={12} />{' '}
            <Skeleton width={20} className="ml-3" height={12} />
          </div>
        ) : (
          <PaymentSummary
            summaryData={{
              total: isPaymentRequired
                ? getTotal().totalInDecimal
                : getPriceInTwoDecimals(0),
              credit:
                calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                  ?.credit &&
                calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                  ?.credit < 0
                  ? `-$${getPriceInTwoDecimals(
                      Math.abs(
                        calculateProrationQuery.data?.[
                          selectedSubscriptionPlanId
                        ]?.credit
                      ) || 0
                    )}`
                  : '',
              creditDescription:
                calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                  ?.creditNote,
              debit: calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                ?.debit
                ? getPriceInTwoDecimals(
                    calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                      ?.debit || 0
                  )
                : '',
              debitDescription:
                calculateProrationQuery.data?.[selectedSubscriptionPlanId]
                  ?.debitNote,
              creditUsed:
                getTotal().creditUsed > 0
                  ? getPriceInTwoDecimals(getTotal().creditUsed)
                  : null,
              discount:
                subscriptionState?.canInitiateFirstSubscription &&
                billingPlansQuery.data.data.coupons.length
                  ? isPaymentRequired
                    ? getPriceInTwoDecimals(getTotal().discountedAmount)
                    : getPriceInTwoDecimals(0)
                  : null,
              subscriptionCharge:
                subscriptionState?.canInitiateFirstSubscription
                  ? getPriceInTwoDecimals(
                      Number(selectedSubscriptionPlan?.amount || 0)
                    )
                  : '',
              indivudualProductsCharge: individualProducts.length
                ? getPriceInTwoDecimals(
                    individualProducts.reduce(
                      (acc, item) => acc + Number(item?.product?.amount),
                      0
                    )
                  )
                : '',
              shippingProductCharge: shipmentTypeProducts.length
                ? getPriceInTwoDecimals(
                    Number(selectedShipmentPlan?.amount || 0)
                  )
                : '',
            }}
          />
        )}
      </div>

      <div className="mt-4 w-full pl-7 pr-7">
        <Button
          onClick={handlePaymentPlanSubmit}
          fullWidth
          disabled={isLoading}
          loading={
            createSubscriptionMutation.isLoading ||
            updateSubscriptionMutation.isLoading
          }>
          Next
        </Button>
      </div>
    </div>
  );
}
