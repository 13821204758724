import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { PatientInfo } from 'components/patient/patientInfo/PatientInfo';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';

const tabs = [
  { title: 'Overview', link: 'overview' },
  { title: 'Subscription', link: 'subscription' },
];

type GenderType = 'M' | 'F';

export type PatientContextType = {
  gender: GenderType;
  id: string;
};

export function PatientOverviewLayout() {
  const { id } = useParams();

  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id || ''),
    () => UserService.getPatientDetails(id || ''),
    {
      enabled: !!id,
    }
  );

  return (
    <div className="pb-6">
      {!patientDetailsQuery.isSuccess ? (
        <div className="grid grid-cols-[max-content_20rem_30rem] place-content-start items-center gap-x-10 rounded-md bg-background-main p-5">
          <Skeleton circle width={90} height={90} />
          <Skeleton
            inline
            containerClassName="grid gap-y-3 pr-[4rem]"
            count={3}
          />
          <Skeleton
            inline
            containerClassName="grid gap-y-3 px-[4rem]"
            count={3}
          />
          <div />
        </div>
      ) : (
        <PatientInfo details={patientDetailsQuery.data.data} />
      )}
      <div className=" mt-[2px] flex w-full bg-background-light shadow-md ">
        {tabs.map((tab) => (
          <NavLink
            to={tab.link}
            className={({ isActive }) =>
              `inline-block px-4 py-3  text-md font-semibold  ${
                isActive ? 'text-primary-main ' : 'text-zinc-400 '
              }`
            }>
            <div
              key={tab.title}
              className={`inline-block pr-20 text-lg font-semibold  ${
                tab.title === 'Overview' && 'ml-4'
              }`}>
              <Typography style={{ color: 'inherit' }} variant="h3">
                {tab.title}
              </Typography>
            </div>
          </NavLink>
        ))}
      </div>
      <Outlet
        context={
          {
            id: patientDetailsQuery.isSuccess
              ? patientDetailsQuery.data.data.id
              : '',
            gender: patientDetailsQuery.isSuccess
              ? patientDetailsQuery.data.data.patientDetails.gender
              : '',
          } as PatientContextType
        }
      />
    </div>
  );
}
