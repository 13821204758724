import React from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { BillingService } from 'services/billing';
import { UserService } from 'services/user';
import { digitToAlphabetic, getReadableDate } from 'utils/common';
import { getPriceInTwoDecimals } from 'utils/price-calculation';

import { PaymentMethods } from './PaymentMethods/PaymentMethods';

enum BillingCycle {
  annual = 'yearly',
  monthly = 'month',
}

export function Subscription() {
  const { id: patientId } = useParams();
  const activeSubscriptionQuery = useQuery(
    QueryKeys.Subscription.item(patientId || ''),
    () => BillingService.getActiveSubscription(patientId || '')
  );

  const creditBalanceQuery = useQuery(
    QueryKeys.Balance.item(patientId || ''),
    () => BillingService.getCreditBalance(patientId || '')
  );

  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(patientId || ''),
    () => UserService.getPatientDetails(patientId || '')
  );

  const paymentMethodsQuery = useQuery(
    QueryKeys.PaymentMethod.item(patientId || ''),
    () => BillingService.getSavedCards(patientId || '')
  );

  const cancelActiveSubscriptionMutation = useMutationWithToast(
    BillingService.cancelActiveSubscription
  );
  const queryClient = useQueryClient();

  const handleCancelActiveSubscription = () => {
    if (!patientId) return;
    cancelActiveSubscriptionMutation.mutate(patientId, {
      onSuccess: () =>
        queryClient.refetchQueries(
          QueryKeys.Subscription.item(patientId || '')
        ),
    });
  };

  const getAddressStatement = (address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    state: {
      id: string;
      name: string;
      abbreviation: string;
    };
    zipCode: string;
  }) => {
    if (!address) return 'NA';

    return `${address?.addressLine1}, ${
      address.addressLine2 ? `${address.addressLine2}, ` : ''
    }
            ${address.city || ''}, ${address.state.name || ''}, ${
      address.country || ''
    }, ${address.zipCode || ''}`;
  };

  const isLoading =
    !activeSubscriptionQuery.isSuccess &&
    !creditBalanceQuery.isSuccess &&
    !activeSubscriptionQuery.isError;

  return (
    <div className="mt-1">
      {isLoading ? (
        <div className="grid gap-y-3 rounded-md bg-background-main p-5">
          <Skeleton width={300} height={20} />
          <Skeleton width={600} height={20} />
          <Skeleton width={250} height={15} />
          <Skeleton width={200} height={15} />
        </div>
      ) : (
        <div className="flex flex-col space-y-3 rounded-md bg-background-main p-5">
          {!activeSubscriptionQuery?.data?.data?.subscription ? (
            <Typography variant="subtitle2">
              No active subscriptions found.
            </Typography>
          ) : (
            <>
              <Typography variant="h4">Your Active Subscription</Typography>
              <Typography variant="subtitle2">
                <span>
                  You are subscribed to{' '}
                  <span className="font-bold uppercase text-primary-main">
                    {digitToAlphabetic(
                      +activeSubscriptionQuery.data.data.subscription
                        .subscriptionPackage.count
                    )}{' '}
                  </span>{' '}
                  hormone{' '}
                  {+activeSubscriptionQuery.data.data.subscription
                    .subscriptionPackage.count > 1
                    ? 'prescriptions'
                    : 'prescription'}{' '}
                  at only <span className="text-xl text-primary-main">$</span>
                  <span className="font-b text-primary-main">
                    {getPriceInTwoDecimals(
                      activeSubscriptionQuery.data.data.subscription
                        .subscriptionPackage.amount
                    )}
                    /
                    {
                      BillingCycle[
                        activeSubscriptionQuery.data.data.subscription
                          .subscriptionPackage.billingCycle as
                          | 'monthly'
                          | 'annual'
                      ]
                    }
                  </span>
                  .
                </span>
              </Typography>
              <Typography variant="subtitle3">
                {activeSubscriptionQuery.data.data.upcomingInvoice ? (
                  <span>
                    Next invoice on{' '}
                    <span className="text-primary-main">
                      {getReadableDate(
                        activeSubscriptionQuery.data.data.upcomingInvoice
                          .dueDate,
                        false,
                        true
                      )}{' '}
                    </span>
                    at <span className="text-xl text-primary-main">$</span>
                    <span className="text-primary-main">
                      {getPriceInTwoDecimals(
                        activeSubscriptionQuery.data.data.upcomingInvoice
                          .periodEnd
                      )}
                    </span>
                    .
                  </span>
                ) : (
                  <span>
                    Your subscription will be active till{' '}
                    <span className="text-primary-main">
                      {getReadableDate(
                        activeSubscriptionQuery.data.data.subscription
                          .stripeSubscriptionPeriodEnd,
                        false,
                        true
                      )}
                    </span>
                    .
                  </span>
                )}
              </Typography>
              {!!creditBalanceQuery?.data?.data && (
                <Typography variant="subtitle3">
                  <span>
                    Your credit balance is{' '}
                    <span className="text-xl text-primary-main">$</span>
                    <span className="text-primary-main">
                      {getPriceInTwoDecimals(
                        creditBalanceQuery.data.data.balance || 0
                      )}
                    </span>
                  </span>{' '}
                  .
                </Typography>
              )}
            </>
          )}
        </div>
      )}
      <div className="mt-1 grid grow grid-cols-2 gap-1">
        {patientDetailsQuery.status !== 'success' ? (
          <div className="grid rounded-md bg-background-main p-5">
            <Skeleton height={15} inline />
            <Skeleton height={15} inline width="70%" />
          </div>
        ) : (
          <div className="flex flex-col gap-5 rounded-md bg-background-main p-5">
            <Typography variant="h4">Billing Address</Typography>
            <Typography variant="subtitle2">
              {getAddressStatement(
                patientDetailsQuery.data.data.patientBillingAddress
              )}
            </Typography>
          </div>
        )}
        {patientDetailsQuery.status !== 'success' ? (
          <div className="grid rounded-md bg-background-main p-5">
            <Skeleton height={15} inline />
            <Skeleton height={15} inline width="70%" />
          </div>
        ) : (
          <div className="flex flex-col gap-5 rounded-md bg-background-main p-5">
            <Typography variant="h4">Shipping Address</Typography>
            <Typography variant="subtitle2">
              {getAddressStatement(
                patientDetailsQuery.data.data.patientShippingAddress
              )}
            </Typography>
          </div>
        )}
      </div>
      <div className="mt-1 grid grow grid-cols-2 gap-1">
        <div className="flex flex-col">
          {!paymentMethodsQuery.isSuccess || !patientDetailsQuery.isSuccess ? (
            <div className="grid gap-y-3 rounded-md bg-background-main p-5">
              <Skeleton height={20} inline width={250} />
              <div className="mt-2 grid">
                <div className="rounded-md border border-zinc-600 p-3">
                  <div className="flex items-center justify-between">
                    <Skeleton height={20} width={200} inline />
                    <Skeleton height={30} width={120} inline />
                  </div>
                  <Skeleton height={15} width={250} />
                  <Skeleton height={15} width={150} inline />
                </div>
              </div>
              <Skeleton
                containerClassName="grid mx-auto mt-5"
                height={35}
                width={200}
                inline
              />
            </div>
          ) : (
            <PaymentMethods
              patientDetails={{
                email: patientDetailsQuery.data.data.email,
                name: `${patientDetailsQuery.data?.data.firstName} ${patientDetailsQuery.data.data.lastName}`,
              }}
              paymentMethods={paymentMethodsQuery.data.data}
              hasActiveSubscription={!!activeSubscriptionQuery?.data?.data}
            />
          )}
        </div>
        {isLoading ? (
          <div className="grid gap-y-5 rounded-md bg-background-main p-5">
            <Skeleton height={15} inline width={500} />
            <div className="grid">
              <Skeleton height={10} width={500} inline count={3} />
              <Skeleton height={10} width={350} inline />
            </div>
            <Skeleton
              containerClassName="grid mx-auto mt-5"
              height={35}
              width={200}
              inline
            />
          </div>
        ) : (
          <div className="flex flex-col gap-8 rounded-md bg-background-main p-5">
            <Typography variant="h4" color="error">
              Do you want to cancel the subscription for this patient?
            </Typography>
            <Typography variant="subtitle2">
              Please note that once the subscription is cancelled patient will
              no longer be able to use the mobile app to follow the routine,
              order refills, report symptoms and receive remainder
              notifications. Additionally, the provider won&apos;t be able to
              prescribe new Rx(s) to this patient.
            </Typography>
            <span
              className="mx-auto inline-block"
              data-tooltip-id="cancel_sub_button">
              <Button
                disabled={
                  cancelActiveSubscriptionMutation.isLoading ||
                  !activeSubscriptionQuery?.data?.data?.upcomingInvoice
                }
                loading={cancelActiveSubscriptionMutation.isLoading}
                onClick={handleCancelActiveSubscription}
                size="medium"
                className=" w-max"
                color="error"
                variant="contained">
                Cancel Subscription
              </Button>
            </span>
            {!activeSubscriptionQuery?.data && (
              <Tooltip id="cancel_sub_button">
                No active subscription found.
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
