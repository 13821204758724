import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { BillingService } from 'services/billing';

import { CardDetailsForm } from '../CardDetailsForm/CardDetailsFrom';

type AddPaymentMethodProps = {
  openModal: boolean;
  onModalClose: () => void;
  amount?: number;
  onCardAdded: () => void;
  patientDetails: { name: string; email: string };
};

export function AddPaymentMethodModal({
  openModal,
  onModalClose,
  amount,
  onCardAdded,
  patientDetails,
}: AddPaymentMethodProps) {
  const { id: pateintId } = useParams();
  const setupIntentQuery = useQuery(QueryKeys.BillingPlans.listings(), () =>
    BillingService.getSetUpIntent(pateintId || '')
  );

  return (
    <Modal
      disableClickOutside
      title="Add Payment method"
      open={openModal}
      className="w-2/5"
      closeModal={onModalClose}>
      <CardDetailsForm
        amount={amount}
        clientSecret={setupIntentQuery?.data?.data.intent || ''}
        patientDetails={patientDetails}
        onCardAdded={onCardAdded}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}
