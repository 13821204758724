import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useSubscriptionState } from 'hooks/usePatientSubscriptionState';

import { SelectPaymentPlan } from '../SelectPaymentPlan/SelectPaymentPlan';

type UpdateSubscriptionProps = {
  onSubscriptionUpdate: () => void;
};

export function UpdateSubscription({
  onSubscriptionUpdate,
}: UpdateSubscriptionProps) {
  const queryClient = useQueryClient();

  const patientSubscriptionState = useSubscriptionState();

  return (
    <div className="w-full">
      <Typography variant="h4">
        {patientSubscriptionState?.isNonMedicationPayment
          ? 'Update Subscription'
          : 'Medications Payment'}
      </Typography>

      <div className="mt-2">
        <SelectPaymentPlan
          onSubscriptionSuccess={async () => {
            const key = QueryKeys.PatientPrescription.all();
            await queryClient.resetQueries(key);
            await queryClient.refetchQueries(key);
            await onSubscriptionUpdate();
          }}
        />
      </div>
    </div>
  );
}
