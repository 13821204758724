import { APIService } from 'services/api-service';

import { OnboardServiceNS } from './types';

export class OnboardService extends APIService {
  static ONBOARD_PRACTICE_ADMIN_URL = `${this.API_PREFIX_URL}/onboard/practice-admin`;
  static onBoardPracticeAdmin = async ({
    token,
    practiceAdminDetails,
  }: OnboardServiceNS.OnboardPracticeAdminBody) =>
    this.post(
      `${OnboardService.ONBOARD_PRACTICE_ADMIN_URL}?token=${token}`,
      { ...practiceAdminDetails },
      {
        withAuthToken: true,
      }
    );
}
