import { Typography } from 'components/common/Typography/Typography';

type PaymentSummaryProps = {
  summaryData: {
    debit?: string;
    debitDescription?: string;
    credit?: string;
    creditDescription?: string;
    subscriptionCharge?: string;
    indivudualProductsCharge?: string;
    shippingProductCharge?: string;
    creditUsed: string | null;
    discount: string | null;
    total: string;
  };
};

export function PaymentSummary({ summaryData }: PaymentSummaryProps) {
  const {
    credit,
    creditDescription,
    creditUsed,
    debitDescription,
    debit,
    discount,
    total,
    subscriptionCharge,
    indivudualProductsCharge,
    shippingProductCharge,
  } = summaryData;

  return (
    <div>
      {creditDescription && credit ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            {creditDescription}
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              {credit}
            </Typography>
          </div>
        </div>
      ) : null}
      {debitDescription && debit ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            {debitDescription}
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {debit}
            </Typography>
          </div>
        </div>
      ) : null}
      {subscriptionCharge ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            Subscription charge
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {subscriptionCharge}
            </Typography>
          </div>
        </div>
      ) : null}
      {indivudualProductsCharge ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            Rx(s) not covered under subscription
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {indivudualProductsCharge}
            </Typography>
          </div>
        </div>
      ) : null}
      {shippingProductCharge ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            Shipment charge
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {shippingProductCharge}
            </Typography>
          </div>
        </div>
      ) : null}
      {discount != null ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            Extra Discount
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {discount}
            </Typography>
          </div>
        </div>
      ) : null}

      {creditUsed != null ? (
        <div className="mb-1 flex justify-between">
          <Typography className="max-w-[14rem]" variant="body2">
            Credit used
          </Typography>
          <div className="flex">
            <Typography variant="body2" color="primary">
              $
            </Typography>
            <Typography variant="body2" color="primary">
              {creditUsed}
            </Typography>
          </div>
        </div>
      ) : null}
      <div className="mb-1 flex justify-between">
        <Typography className="max-w-[14rem]" variant="body1">
          Total payable amount
        </Typography>
        <div className="flex">
          <Typography variant="body1" color="primary">
            $
          </Typography>
          <Typography variant="subtitle2" color="primary">
            {total}
          </Typography>
        </div>
      </div>
    </div>
  );
}
