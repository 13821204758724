/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { BillingService } from 'services/billing';
import { SubscriptionDetailsType } from 'types/subscription/types';
import { getPriceInTwoDecimals } from 'utils/price-calculation';

const textInputClasses =
  'px-2 py-3.5 rounded-md shadow-sm focus:outline-none placeholder:text-zinc-400 placeholder:text-md text-md bg-background-light w-full text-background-contrastText';

const CARD_ELEMENT_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: 'rgb(240, 57, 122)',
      color: '#000',
      fontSize: '12px',
      letterSpacing: '1.5px',
      fontFamily: '"Verdana", "sans-serif"',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#808080',
      },
    },
    invalid: {
      color: '#791414',
      ':focus': {
        color: '#791414',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

type ConfirmCardPaymentResult = {
  paymentIntent: {
    payment_method: string;
  };
};
type ConfirmCardSetupResult = {
  setupIntent: {
    payment_method: string;
  };
};

export function AddCard({
  onCardAdded,
  subscriptionDetails,
  patientDetails,
  onModalClose,
}: {
  onCardAdded: () => void;
  onModalClose?: () => void;
  subscriptionDetails: SubscriptionDetailsType;
  patientDetails: { name: string; email: string };
}) {
  const stripeInstance = useStripe();
  const elements: any = useElements();
  const { id } = useParams();
  const stripe: Stripe | null = stripeInstance as Stripe;
  const clientSecretKeyRef = useRef(subscriptionDetails.clientSecret);

  const cardNumberError = useRef(false);
  const cardExpiryError = useRef(false);
  const cardCvcError = useRef(false);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [enableActionButton, setEnableActionButton] = useState(false);

  const defaultPaymentMethodMutation = useMutationWithToast(
    BillingService.setDefaultPaymentMethod
  );

  const setCardAsDefault = (paymentMethodId: string) => {
    defaultPaymentMethodMutation.mutate(
      { paymentMethodId, patientId: id || '' },
      {
        onSuccess: async () => {
          onCardAdded();
        },
        onError: async () => {
          setIsLoading(false);
          onCardAdded();
        },
      }
    );
  };

  const handlePayment = async (isPayment: boolean) => {
    if (!subscriptionDetails.clientSecret) {
      toast.error('Something went wrong.');
      setIsLoading(false);

      return;
    }
    setIsLoading(true);

    const paymentFunc = isPayment
      ? stripe.confirmCardPayment
      : stripe.confirmCardSetup;
    const result = await paymentFunc(clientSecretKeyRef.current, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: patientDetails.name,
          email: patientDetails.email,
        },
      },
    });

    if (result.error) {
      toast.error('Something went wrong.');
      setIsLoading(false);
    } else {
      const paymentMethodId = isPayment
        ? (result as ConfirmCardPaymentResult).paymentIntent?.payment_method
        : (result as ConfirmCardSetupResult).setupIntent?.payment_method;

      await setCardAsDefault(paymentMethodId || '');
    }
  };

  const handleChange = (e: any) => {
    if (e.elementType === 'cardNumber') {
      if (!e.complete) {
        cardNumberError.current = false;
        setError(e?.error?.message);
        setEnableActionButton(false);

        return;
      }
      cardNumberError.current = true;
    }
    if (e.elementType === 'cardExpiry') {
      if (!e.complete) {
        cardExpiryError.current = false;
        setError(e?.error?.message);

        setEnableActionButton(false);

        return;
      }
      cardExpiryError.current = true;
    }
    if (e.elementType === 'cardCvc') {
      if (!e.complete) {
        cardCvcError.current = false;
        setError(e?.error?.message);
        setEnableActionButton(false);

        return;
      }
      cardCvcError.current = true;
    }

    if (
      cardCvcError.current &&
      cardExpiryError.current &&
      cardNumberError.current
    ) {
      setEnableActionButton(true);
      setError('');
    }
  };

  return (
    <div className="mt-4  grid gap-3  ">
      <div className="grid gap-2">
        <Typography variant="h4">
          What are your{' '}
          <Typography variant="h4" color="primary">
            &nbsp;credit card details?
          </Typography>
        </Typography>
      </div>
      <Typography className="-mb-2.5" variant="body1">
        Card Number
      </Typography>
      <div className={classNames(textInputClasses)}>
        <CardNumberElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Typography className="mb-0.5" variant="body1">
            Expiration Date
          </Typography>
          <div className={classNames(textInputClasses)}>
            <CardExpiryElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-span-6">
          <Typography className="mb-0.5" variant="body1">
            Security Code
          </Typography>
          <div className={classNames(textInputClasses)}>
            <CardCvcElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <Typography color="error" className="h-5">
        {error}
      </Typography>

      <div className="mt-6 flex justify-end">
        <Button color="secondary" variant="contained" onClick={onModalClose}>
          Close
        </Button>
        <span className="mx-2" />
        <Button
          onClick={() => handlePayment(!!subscriptionDetails.amount)}
          disabled={!enableActionButton}
          loading={isLoading}>
          {subscriptionDetails.amount
            ? `Pay $${getPriceInTwoDecimals(subscriptionDetails.amount)}`
            : 'Add Card'}
        </Button>
      </div>
    </div>
  );
}
