import React, { useCallback, useEffect, useState } from 'react';

import { useFloating } from '@floating-ui/react-dom';
import { Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';

import { ControllerOnChangeType } from 'types/input-types';

import { SearchInput } from '../SearchInput/SearchInput';

interface Option {
  // eslint-disable-next-line
  title: string;
  // eslint-disable-next-line
  value: string | number;
}

type ControlProps =
  | {
      control?: unknown;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };

type DropDownProps = {
  options: Option[] | undefined;
  label: string;
  defaultValue?: Option;
  searchable?: boolean;
  onChange?: (value: Option) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  disableHelperText?: boolean;
  error?: boolean;
  theme?: 'dark' | 'light';
} & ControlProps;
export function DropDown({
  label,
  options,
  defaultValue,
  searchable = false,
  onChange,
  fullWidth,
  disabled,
  className,
  control,
  name,
  helperText,
  disableHelperText,
  error,
  theme = 'dark',
}: DropDownProps) {
  const [searchText, setSearchText] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const { x, y, strategy, refs } = useFloating();

  const closeMenu = useCallback((event: MouseEvent) => {
    if (
      (event?.target as SVGElement).id === 'DropDownbutton' ||
      (event?.target as SVGElement).id === 'searchInput'
    ) {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('click', (e) => closeMenu(e));

    return () => document.removeEventListener('click', closeMenu);
  }, [closeMenu]);

  const getDropdown = useCallback(
    (controllerOnChange?: ControllerOnChangeType) => (
      <div id="DropDownbutton">
        {/*  eslint-disable-next-line */}
        <div
          id="DropDownbutton"
          onClick={() => setOpen(!open)}
          className={classNames(
            'flex w-full cursor-pointer items-center justify-between rounded bg-background-light px-2 py-3 text-md text-zinc-400',
            open ? 'rounded-b-none' : 'rounded',
            disabled
              ? 'pointer-events-none cursor-no-drop opacity-80'
              : 'cursor-pointer',
            {
              'border border-error-main': !!error,
              '!text-background-contrastText': !!value || !!defaultValue?.title,
            }
          )}>
          {value
            ? value?.length > 25
              ? `${value?.substring(0, 20)}...`
              : value
            : defaultValue?.title || label}
          <ChevronDownIcon
            id="DropDownbutton"
            height={20}
            className={`${open && 'rotate-180'}`}
          />
        </div>
        {open && (
          <Portal>
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: refs.reference.current?.getBoundingClientRect().width,
              }}
              className={classNames(
                theme,
                ' -mt-4 max-h-48  overflow-y-auto rounded-b bg-background-light   '
              )}>
              {searchable && (
                <SearchInput
                  id="searchInput"
                  onSearch={(v) => setSearchText(v)}
                />
              )}
              <ul
                className={classNames('p-2 pt-1', {
                  'border-t border-t-zinc-400': searchable,
                })}>
                {options?.map(({ title, value: v }: Option) => (
                  // eslint-disable-next-line
                  <li
                    onClick={
                      control
                        ? () => {
                            setValue(title);
                            if (controllerOnChange) {
                              controllerOnChange({
                                target: {
                                  value: v,
                                  name: name ?? '',
                                },
                              });
                            }
                            setOpen(false);
                          }
                        : () => {
                            if (onChange) {
                              setValue(title);
                              onChange({ title, value: v });
                            }
                            setOpen(false);
                          }
                    }
                    key={title}
                    className={classNames(
                      'block cursor-pointer p-2 pl-4 text-base text-background-contrastText hover:rounded-sm hover:bg-primary-main hover:text-primary-contrastText',
                      {
                        ' border-l-4 border-primary-main pl-3 ':
                          title.toLowerCase() === value?.toLowerCase(),
                        ' hidden ':
                          searchable &&
                          !title
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()),
                      }
                    )}>
                    {title}
                  </li>
                ))}
              </ul>
            </div>
          </Portal>
        )}
      </div>
    ),
    [
      value,
      searchable,
      searchText,
      options,
      open,
      onChange,
      name,
      label,
      refs.reference,
      disabled,
      defaultValue,
      control,
      error,
      theme,
      refs.setFloating,
      strategy,
      x,
      y,
    ]
  );

  return (
    <div
      ref={refs.setReference}
      className={classNames(
        ' relative',
        fullWidth ? 'w-full' : 'w-60',
        className
      )}>
      {control ? (
        <Controller
          control={control as Control}
          name={name as string}
          render={({ field: { onChange: controllerOnChange } }) =>
            getDropdown(controllerOnChange)
          }
        />
      ) : (
        getDropdown()
      )}
      {!disableHelperText && (
        <p
          style={{ minHeight: '1rem' }}
          className={classNames(
            'm-1 text-left  text-sm text-background-contrastText',
            {
              '!text-error-main': !!error,
            }
          )}>
          {helperText}
        </p>
      )}
    </div>
  );
}
