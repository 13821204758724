import { PracticeAddress } from 'types/address';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

export const removeUndefined = <T>(obj: T): NoUndefinedField<T> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(obj).reduce((acc: never, key: never) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }

    return acc;
  }, {}) as unknown as NoUndefinedField<T>;

export const getQueryParamFromURL = ({
  name: _name,
  url = '',
}: {
  name: string;
  url: string;
}) => {
  const name = _name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getReadableDate(
  isoDate: string,
  timeRequired = false,
  withWeekday = false
): string {
  const date = new Date(isoDate);
  const pad = (n: number, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
  const time = `, ${
    date.getHours() > 12
      ? Number(pad(date.getHours())) - 12
      : pad(date.getHours())
  }:${pad(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  if (withWeekday) {
    options.weekday = 'long';
  }

  return `${date.toLocaleString('en-us', options)}${
    timeRequired ? ` ${time}` : ''
  }`;
}

export const getAddressesString = (address: PracticeAddress) =>
  `${address.addressLine1}, ${
    address.addressLine2?.length ? `${address.addressLine2}, ` : ''
  }${address.city}, ${address.state.name}, ${address.country}, ${
    address.zipCode
  }`;

export const splitStringWithQuotes = (userInput: string) => {
  const regex = /\s(?=(?:[^"]|"[^"]*")*$)/g;
  const result = userInput.split(regex);

  if (result[result.length - 1] === '') {
    result.pop();
    result.push(' ');
  }

  return result || [];
};

export const formatDate = (date: Date) => {
  if (Number.isNaN(date.valueOf())) {
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const countDoubleQuotes = (query: string) => {
  const regex = /"/g;
  const matches = query.match(regex);

  return matches ? matches.length : 0;
};

export function normalizeString(s: string) {
  return s.replace(/\s+/g, '-').toLowerCase();
}

export function digitToAlphabetic(number: number): string {
  const singleDigits: string[] = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
  ];

  const teens: string[] = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  const tens: string[] = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  if (number === 0) {
    return 'zero';
  }

  if (number < 10) {
    return singleDigits[number];
  }

  if (number < 20) {
    return teens[number - 10];
  }

  if (number < 100) {
    const ten = Math.floor(number / 10);
    const remainder = number % 10;

    return tens[ten] + (remainder !== 0 ? `-${singleDigits[remainder]}` : '');
  }

  if (number < 1000) {
    const hundred = Math.floor(number / 100);
    const remainder = number % 100;

    return `${singleDigits[hundred]} hundred${
      remainder !== 0 ? ` ${digitToAlphabetic(remainder)}` : ''
    }`;
  }

  return 'number out of range';
}

export function formatPhoneNumber(phoneNumberString?: string) {
  if (!phoneNumberString) {
    return '';
  }
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export const capitalizedString = (string: string) => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
