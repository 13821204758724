import { APIService } from 'services/api-service';
import { PatientMedication, Prescription } from 'types/prescriptionMedication';

import { MedicationServiceNS } from './types';

export class MedicationService extends APIService {
  static GET_PATIENT_MEDICATION = `${this.API_PREFIX_URL}/medication/`;
  static getPatientMedication = async (gender: string) =>
    this.get<PatientMedication[]>(
      `${MedicationService.GET_PATIENT_MEDICATION}?gender=${gender}`,
      {
        withAuthToken: true,
      }
    );

  static MARK_RX = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static markRx = async ({
    patientId,
    status,
  }: MedicationServiceNS.GetMarkRxBody) => {
    const url = new URL(`${this.MARK_RX}/mark-rxs-${status}`);
    url.searchParams.append('patient_id', patientId);

    return this.put<PatientMedication[]>(
      url.toString(),
      {},
      {
        withAuthToken: true,
      }
    );
  };

  static GET_PATIENT_PRESCRIPTION = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static getPatientPrescription = async ({
    category,
    id,
  }: MedicationServiceNS.GetPatientPrescriptionBody) => {
    const url = new URL(this.GET_PATIENT_PRESCRIPTION);
    if (id) {
      url.searchParams.append('patient_id', id);
    }
    if (category) {
      url.searchParams.append('category', category);
    }

    return this.get<Prescription[]>(url.toString(), {
      withAuthToken: true,
    });
  };
}
