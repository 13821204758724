import React from 'react';

import { NavLink, Outlet } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { useProfile } from 'hooks/useProfile';

const tabs = [
  { title: 'Profile', link: 'profile' },
  { title: 'Settings', link: 'settings' },
];

export function UserProfileLayout() {
  const { profile } = useProfile();

  return (
    <div className="ml-4 mt-10">
      <div className="flex">
        <Typography className="mr-2" variant="h1">
          Hello,
        </Typography>
        <Typography variant="h1" color="primary">
          {`${profile?.firstName} ${profile?.lastName}`}
        </Typography>
      </div>
      <div className="mt-14 flex  justify-around ">
        <div className="mr-10">
          <div className="mt-2 flex cursor-pointer flex-col text-base font-semibold text-background-contrastText">
            {tabs.map((tab) => (
              <NavLink
                to={tab.link}
                className={({ isActive }) =>
                  `inline-block py-4  pl-1 text-md   ${
                    isActive
                      ? 'text-primary-main '
                      : 'text-background-contrastText '
                  }`
                }>
                {tab.title}
              </NavLink>
            ))}
          </div>
        </div>
        <span className="mr-20 h-[8rem]  w-[2px] bg-background-light " />
        <div className="h-max grow rounded bg-background-main ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
