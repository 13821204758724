import { Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';
import {
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';
import { useProfile } from 'hooks/useProfile';
import { removeAccessAndRefreshTokens } from 'lib/general/token';

import { Avatar } from '../Avatar/Avatar';

export function AvatarDropdown() {
  const { profile } = useProfile();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    removeAccessAndRefreshTokens();
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    window.location.replace(
      `${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`
    );
  };

  const navLinks = [
    {
      name: 'Profile',
      href: '/app/user/profile',
      icon: UserCircleIcon,
    },
    // {
    //   name: 'Setting',
    //   href: '/user/setting',
    //   icon: Cog6ToothIcon,
    // },
  ];

  return (
    <div className="AvatarDropdown outline-none">
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button className="inline-flex items-center space-x-3 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white   focus-visible:ring-opacity-75">
              <Avatar
                imgUrl={profile?.picture}
                userName={profile?.firstName}
                sizeClass="w-8 h-8 "
              />
              <h1 className="font-bold text-background-contrastText ">
                {`${profile?.firstName} ${profile?.lastName}`}
              </h1>
              <ChevronDownIcon className="h-5 w-5 font-extrabold text-background-contrastText " />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute top-10 z-10  mt-3 w-screen max-w-[260px] sm:-right-5 sm:px-0 lg:-right-8 ">
                <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 ">
                  <div className="relative grid gap-6 bg-background-main bg-opacity-95 p-6  backdrop-blur">
                    {navLinks.map((item) => (
                      <NavLink
                        key={item.href}
                        to={item.href}
                        className="-m-3 flex items-center rounded p-2 transition duration-150 ease-in-out hover:bg-background-light focus:outline-none  focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 active:bg-background-main dark:text-white"
                        onClick={() => {
                          close();
                        }}>
                        <div className="flex flex-shrink-0 items-center justify-center text-background-contrastText">
                          <item.icon aria-hidden="true" className="h-5 w-5" />
                        </div>
                        <div className="ml-4 text-background-contrastText">
                          <p className=" font-medium">{item.name}</p>
                        </div>
                      </NavLink>
                    ))}
                    <NavLink
                      to="#"
                      className="-m-3 flex items-center rounded p-2 transition duration-150 ease-in-out hover:bg-background-light focus:outline-none  focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 active:bg-background-main dark:text-white"
                      onClick={() => {
                        handleLogout();
                        close();
                      }}>
                      <div className="flex flex-shrink-0 items-center justify-center  ">
                        <ArrowLeftOnRectangleIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </div>
                      <div className="ml-4 ">
                        <p className=" font-medium text-background-contrastText">
                          Logout
                        </p>
                      </div>
                    </NavLink>
                  </div>
                  {/* <hr className="h-[1px] border-t border-secondary-300 dark:border-secondary-700" /> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
