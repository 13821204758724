import { APIService } from 'services/api-service';

import { ProfileServiceNS } from './types';

export class ProfileService extends APIService {
  static USER_PROFILE = `${this.API_PREFIX_URL}/profile/`;
  static getUserProfile = async () =>
    this.get<ProfileServiceNS.GetUserProfileResponse>(
      ProfileService.USER_PROFILE,
      {
        withAuthToken: true,
      }
    );

  static GET_STATES = `${this.API_PREFIX_URL}/profile/states`;
  static getStates = async () =>
    this.get<ProfileServiceNS.GetStatesResponse[]>(ProfileService.GET_STATES, {
      withAuthToken: true,
    });

  static UPDATE_USER_PASSWORD = `${this.API_PREFIX_URL}/profile/password`;
  static updateUserPassword = ({
    oldPassword,
    password,
  }: ProfileServiceNS.UpdateUserPasswordBody) =>
    this.put(
      ProfileService.UPDATE_USER_PASSWORD,
      {
        oldPassword,
        newPassword: password,
      },
      {
        withAuthToken: true,
      }
    );

  static PROFILE_DETAILS = `${this.API_PREFIX_URL}/profile/details`;
  static getProfileDetails = () =>
    this.get<ProfileServiceNS.GetUserDetailsResponse>(
      ProfileService.PROFILE_DETAILS,

      {
        withAuthToken: true,
      }
    );

  static PROVIDER_DETAILS = `${this.API_PREFIX_URL}/profile/details`;
  static getProviderDetails = (id: string) => {
    const url = new URL(this.PROVIDER_DETAILS);
    url.searchParams.append('user_id', id);

    return this.get<ProfileServiceNS.GetUserDetailsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static UPDATE_PHONE = `${this.API_PREFIX_URL}/profile/phone`;
  static updatePhoneNumber = ({ phone }: ProfileServiceNS.UpdatePhoneBody) =>
    this.put(
      ProfileService.UPDATE_PHONE,
      {
        phone,
      },
      {
        withAuthToken: true,
      }
    );

  static UPDATE_EMAIL = `${this.API_PREFIX_URL}/profile/email`;
  static updateEmail = ({ email }: ProfileServiceNS.UpdateEmailBody) =>
    this.put(
      ProfileService.UPDATE_EMAIL,
      {
        email,
      },
      {
        withAuthToken: true,
      }
    );

  static VERIFY_EMAIL = `${this.API_PREFIX_URL}/profile/verify-email`;
  static verifyEmail = ({ token }: ProfileServiceNS.VerifyEmailBody) =>
    this.put(
      `${ProfileService.VERIFY_EMAIL}?token=${token}`,
      {},
      {
        withAuthToken: true,
      }
    );

  static UPDATE_PROFILE_PICTURE = `${this.API_PREFIX_URL}/profile/picture`;
  static updateProfilePicture = ({
    picture,
  }: ProfileServiceNS.UpdateProfilePictureBody) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(ProfileService.UPDATE_PROFILE_PICTURE, formData, {
      withAuthToken: true,
      formData: true,
    });
  };

  static deleteProfilePicture = () =>
    this.delete(ProfileService.UPDATE_PROFILE_PICTURE, {
      withAuthToken: true,
    });

  static GET_PATIENT_DETAILS = `${this.API_PREFIX_URL}/profile/details`;
  static getPatientDetails = async (id: string) =>
    this.get<ProfileServiceNS.GetPatientDetailsResponse>(
      `${ProfileService.GET_PATIENT_DETAILS}?user_id=${id}`,
      {
        withAuthToken: true,
      }
    );

  static FORGOT_PASSWORD_URL = `${this.API_PREFIX_URL}/profile/forgot-password`;
  static sendVerificationMail = ({
    email,
  }: ProfileServiceNS.SendVerificationMailBody) =>
    this.post(ProfileService.FORGOT_PASSWORD_URL, {
      email,
    });

  static RESET_PASSWORD_URL = `${this.API_PREFIX_URL}/profile/reset-password`;
  static resetPassword = ({
    password,
    token,
  }: ProfileServiceNS.ResetPasswordArgs) =>
    this.put(`${ProfileService.RESET_PASSWORD_URL}?token=${token}`, {
      new_password: password,
    });
}
