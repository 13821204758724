import classNames from 'classnames';

import { ColumnObject, TableRowProps } from './types';

export function TableRow<TItem>({
  item,
  onRowClick,
  columns,
  isSticky,
}: TableRowProps<TItem>): JSX.Element {
  return (
    <tr
      onClick={() => onRowClick?.(item)}
      className={`${
        onRowClick && 'cursor-pointer hover:bg-background-light'
      } border-b border-b-background-dark bg-background-main text-background-contrastText  `}>
      {columns.map((column: ColumnObject<TItem>, index) => (
        <td
          className={classNames(
            ` whitespace-nowrap px-6 py-3 text-md font-medium ${column?.classNames}`,
            {
              ' sticky bg-background-light':
                isSticky && [columns.length - 1, 0, 1].includes(index),
              ' left-0 shadow-[0px_18px_0px_18px_rgba(38,38,38,1)]  ':
                isSticky && index === 0,
              ' left-32   border-r border-zinc-500': isSticky && index === 1,
              ' right-[-0.3px] border-l border-zinc-500 ':
                isSticky && index === columns.length - 1,
            }
          )}>
          {item && column.render(item)}
        </td>
      ))}
    </tr>
  );
}
