import { Fragment, useState, useEffect, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';

import { Typography } from '../Typography/Typography';

type ConfirmationModalProps = {
  isModalOpen: boolean;
  closeModal: (close: boolean) => void;
  handleConfirm?: (confirm: boolean) => void;
  title?: string;
  infoContent?: ReactNode;
  confirmText?: string;
  infoButtonCancelText?: string;
};

export function ConfirmationModal({
  isModalOpen,
  closeModal,
  handleConfirm,
  confirmText,
  title,
  infoButtonCancelText,
  infoContent,
}: ConfirmationModalProps) {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="light relative z-10"
        onClose={() => closeModal(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Dialog.Overlay className="fixed inset-0 bg-background-main bg-opacity-20 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className=" w-2/5 transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all ">
                <Dialog.Title>
                  <Typography variant="h5">{title}</Typography>
                </Dialog.Title>
                <div className="mt-2">
                  <Typography variant="body2">{infoContent}</Typography>
                </div>

                <div
                  className={classNames('mt-6  flex', {
                    'justify-end': confirmText,
                    'justify-center': !confirmText,
                  })}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => closeModal(true)}>
                    {infoButtonCancelText || 'Close'}
                  </Button>{' '}
                  <span className="mx-4" />
                  {confirmText && (
                    <Button onClick={() => handleConfirm?.(true)}>
                      {confirmText || 'Confirm'}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
