/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { BILLING_CYCLES } from 'types/payment/types';

import 'react-loading-skeleton/dist/skeleton.css';

type BillingPlanObject = {
  id: string;
  name: string;
  priceInDecimal: string;
  recurringInterval: BILLING_CYCLES;
};

type SubscriptionProductPricingCardProps = {
  updateSelectedPlan: (planId: string) => void;
  selectedPlanId: string;
  subscriptionProducts: BillingPlanObject[];
  isLoading?: boolean;
  preferredPlan: string;
};

export function SubscriptionProductPricingCard({
  selectedPlanId,
  subscriptionProducts = [],
  isLoading = false,
  updateSelectedPlan,
  preferredPlan,
}: SubscriptionProductPricingCardProps) {
  const annualPlanDetails = subscriptionProducts
    .filter((item) => item.recurringInterval === BILLING_CYCLES.Yearly)
    .map((i) => i.id)
    .reverse();

  const monthlyPlanDetails = subscriptionProducts
    .filter((item) => item.recurringInterval === BILLING_CYCLES.Monthly)
    .map((i) => i.id)
    .reverse();

  const selectedRecurringInterval = subscriptionProducts.find(
    (item) => item.id === selectedPlanId
  )?.recurringInterval;

  useEffect(() => {
    if (!selectedPlanId) {
      updateSelectedPlan(
        (preferredPlan !== 'monthly'
          ? annualPlanDetails
          : monthlyPlanDetails)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        className={`mt-2 mb-2 cursor-pointer rounded-lg bg-white p-2 shadow-sm ${
          selectedRecurringInterval === BILLING_CYCLES.Yearly
            ? 'border-primary-main'
            : 'border-gray-200'
        } border`}
        onClick={() => updateSelectedPlan(annualPlanDetails[0])}>
        {isLoading ? (
          <div>
            <div className=" -mb-1 flex justify-between">
              <div className="flex">
                {' '}
                <Skeleton circle width={15} />{' '}
                <Skeleton count={1} width={130} height={12} className="ml-2" />
              </div>
            </div>
            <div className="flex justify-between">
              <Skeleton className="ml-6" height={10} width={120} />{' '}
              <Skeleton width={30} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex ">
              <RadioButton
                id={annualPlanDetails[0]}
                checked={selectedRecurringInterval === BILLING_CYCLES.Yearly}
                label="Pay Annually"
                onChange={() => updateSelectedPlan(annualPlanDetails[0])}
              />
              <div
                className="ml-3 pl-2 pr-2"
                style={{
                  borderWidth: 1,
                  borderColor: '#EC6761BF',

                  borderRadius: 20,
                }}>
                <Typography style={{ color: '#EC6761BF' }} variant="body2">
                  Save 20%
                </Typography>
              </div>
            </div>
            <div className="mt-2 mb-1 ml-4">
              {annualPlanDetails.length
                ? annualPlanDetails.map((item) => {
                    const plan = subscriptionProducts.find(
                      (p) => p.id === item
                    ) as BillingPlanObject;

                    return (
                      <div key={plan.name} className="flex justify-between">
                        <div>
                          <Typography variant="body2" color="primary">
                            {plan.name.split(' ')[0].toUpperCase()}{' '}
                            <Typography variant="body2" color="primary">
                              &nbsp;{plan.name.split(' ')[1]} prescription at
                            </Typography>
                          </Typography>
                        </div>
                        <div className="flex justify-between">
                          <Typography variant="body2" color="primary">
                            $
                          </Typography>
                          <Typography variant="body2" color="primary">
                            {plan.priceInDecimal}
                          </Typography>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </>
        )}
      </div>

      <div
        className={`mt-2 mb-2 cursor-pointer rounded-lg bg-white p-2 shadow-sm ${
          selectedRecurringInterval === BILLING_CYCLES.Monthly
            ? 'border-primary-main'
            : 'border-gray-200'
        } border`}
        onClick={() => updateSelectedPlan(monthlyPlanDetails[0])}>
        {isLoading ? (
          <div>
            <div className=" -mb-1 flex justify-between">
              <div className="flex">
                {' '}
                <Skeleton circle width={15} />{' '}
                <Skeleton count={1} width={130} height={12} className="ml-2" />
              </div>
            </div>
            <div className="flex justify-between">
              <Skeleton className="ml-6" height={10} width={120} />{' '}
              <Skeleton width={30} />
            </div>
          </div>
        ) : (
          <>
            <RadioButton
              id={monthlyPlanDetails[0]}
              checked={selectedRecurringInterval === BILLING_CYCLES.Monthly}
              label="Pay Monthly"
              onChange={() => updateSelectedPlan(monthlyPlanDetails[0])}
            />
            <div className="mt-2 mb-1 ml-4">
              {monthlyPlanDetails.length
                ? monthlyPlanDetails.map((item) => {
                    const plan = subscriptionProducts.find(
                      (p) => p.id === item
                    ) as BillingPlanObject;

                    return (
                      <div key={plan.name} className="flex justify-between">
                        <Typography variant="body2" color="primary">
                          {plan.name.split(' ')[0]?.toUpperCase()}{' '}
                          <Typography variant="body2" color="primary">
                            &nbsp;{plan.name.split(' ')[1]} prescription at
                          </Typography>
                        </Typography>
                        <div className="flex justify-between">
                          <Typography variant="body2" color="primary">
                            $
                          </Typography>
                          <Typography variant="body2" color="primary">
                            {plan.priceInDecimal}
                          </Typography>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
