import classNames from 'classnames';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import LogoWhite from 'assets/images/White Logo.png';

export function AuthLayout() {
  const location = useLocation();

  if (location?.pathname === '/auth' || location?.pathname === '/auth/') {
    return <Navigate to="./login" />;
  }

  if (location?.pathname.includes('/auth/verify-email')) {
    return <Outlet />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className="hidden  min-h-screen w-1/2 bg-background-main md:flex">
        <div className="flex h-full w-full items-center justify-center">
          <img src={LogoWhite} alt="Logo" style={{ maxHeight: '100vh' }} />
        </div>
      </div>
      <div className="grid min-h-screen w-full place-items-center bg-background-dark md:w-1/2">
        <div
          className={classNames(
            'w-full rounded-md border-background-contrastText bg-background-main shadow-md',
            {
              'max-w-md': !location?.pathname.includes('/auth/onboard'),
            }
          )}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
