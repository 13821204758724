import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { emailSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { ProfileService } from 'services/profile';

export function ForgotPassword() {
  const navigate = useNavigate();
  const sendVerificationMailMutation = useMutationWithToast(
    ProfileService.sendVerificationMail,
    {}
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });
  const onSubmit = handleSubmit((data) => {
    sendVerificationMailMutation.mutate(
      { email: data.email },
      {
        onSuccess: () => {
          navigate({ pathname: '../login' });
        },
      }
    );
  });

  return (
    <form
      onSubmit={onSubmit}
      className="grid place-items-center rounded-sm px-8 py-10 pb-8">
      <h1 className="mb-8 text-white">Password Reset</h1>

      <TextInput
        type="text"
        name="email"
        control={control}
        helperText={
          extractValidationErrors('email', sendVerificationMailMutation, errors)
            .msg
        }
        error={
          extractValidationErrors('email', sendVerificationMailMutation, errors)
            .hasError
        }
        placeholder="Enter email address"
        fullWidth
      />
      <div className="row mt-2 flex w-full items-center justify-end">
        <Button type="submit" loading={sendVerificationMailMutation.isLoading}>
          Reset Password
        </Button>
      </div>
    </form>
  );
}
