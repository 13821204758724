import { createBrowserRouter, Navigate } from 'react-router-dom';

import { Page } from 'components/common/page/Page';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { RedirectToAuth } from 'components/general/RedirectToAuth/RedirectToAuth';
import { ProviderOverview } from 'components/provider/ProviderOverview/ProviderOverview';
import { AppLayout } from 'pages/app/AppLayout';
import { Dashboard } from 'pages/app/dashboard/Dashboard';
import { Page404 } from 'pages/app/misc/Page404';
import { OrderOverview } from 'pages/app/orders/OrderOverview/OrderOverview';
import { Orders } from 'pages/app/orders/Orders';
import { PatientOverview } from 'pages/app/patients/[id]/overview/Overview';
import { PatientOverviewLayout } from 'pages/app/patients/[id]/PatientOverviewLayout';
import { Subscription } from 'pages/app/patients/[id]/Subscription/Subscription';
import { ActivePatients } from 'pages/app/patients/ActivePatients/ActivePatients';
import { InvitedPatients } from 'pages/app/patients/InvitedPatients/InvitedPatients';
import { PatientLayout } from 'pages/app/patients/PatientLayout';
import { ActiveProviders } from 'pages/app/providers/ActiveProviders/ActiveProviders';
import { InvitedProviders } from 'pages/app/providers/InvitedProviders/InvitedProviders';
import { ProviderLayout } from 'pages/app/providers/ProviderLayout';
import { UserProfile } from 'pages/app/user-profile/UserProfile/UserProfile';
import { UserProfileLayout } from 'pages/app/user-profile/UserProfileLayout';
import { UserSetting } from 'pages/app/user-profile/UserSetting/UserSetting';
import { AuthLayout } from 'pages/auth/AuthLayout';
import { ForgotPassword } from 'pages/auth/forgot-password/ForgotPassword';
import { PracticeAdminOnboard } from 'pages/auth/onboard/PracticeOnboard';
import { ResetPassword } from 'pages/auth/reset-password/ResetPassword';
import { VerifyEmail } from 'pages/auth/verify-email/VerifyEmail';
import { Redirect } from 'pages/redirect/Redirect';

export const routes = createBrowserRouter([
  {
    path: '/redirect',
    element: (
      <Page title="Login">
        <Redirect />
      </Page>
    ),
  },
  {
    path: '',
    element: (
      <RequireAuth>
        <Navigate to="/app" />
      </RequireAuth>
    ),
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    errorElement: <Page404 />,
    children: [
      {
        path: 'login',
        element: (
          <Page title="Login">
            <RedirectToAuth />
          </Page>
        ),
      },
      {
        path: 'onboard',
        element: (
          <Page title="Onboard">
            <PracticeAdminOnboard />
          </Page>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <Page title="Forgot Password">
            <ForgotPassword />
          </Page>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <Page title="Reset password">
            <ResetPassword />
          </Page>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <Page title="Verify email">
            <VerifyEmail />
          </Page>
        ),
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: '/app',
    element: <AppLayout />,
    errorElement: <Page404 />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Page title="Dashboard">
            <Dashboard />
          </Page>
        ),
      },
      {
        path: 'providers',
        element: (
          <Page title="Providers">
            <ProviderLayout />
          </Page>
        ),
        children: [
          {
            path: 'invited-providers',
            element: (
              <Page title="Invited Providers">
                <InvitedProviders />
              </Page>
            ),
          },
          {
            path: 'active-providers',
            element: (
              <Page title="Active Providers">
                <ActiveProviders />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'providers/:id/overview',
        element: (
          <Page title="Overview">
            <ProviderOverview />
          </Page>
        ),
      },
      {
        path: 'orders',
        element: (
          <Page title="Orders">
            <Orders />
          </Page>
        ),
      },
      {
        path: 'orders/:id/overview',
        element: (
          <Page title="Overview">
            <OrderOverview />
          </Page>
        ),
      },
      {
        path: 'patients',
        element: (
          <Page title="Patients">
            <PatientLayout />
          </Page>
        ),
        children: [
          {
            path: 'invited-patients',
            element: (
              <Page title="Invited Patients">
                <InvitedPatients />
              </Page>
            ),
          },
          {
            path: 'active-patients',
            element: (
              <Page title="Active Patients">
                <ActivePatients />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'patients/:id',
        element: (
          <Page title="Overview">
            <PatientOverviewLayout />
          </Page>
        ),
        children: [
          {
            path: 'overview',
            element: (
              <Page title="Overview">
                <PatientOverview />
              </Page>
            ),
          },
          {
            path: 'subscription',
            element: (
              <Page title="Subscription">
                <Subscription />
              </Page>
            ),
          },
        ],
      },
      {
        path: 'user',
        element: <UserProfileLayout />,
        errorElement: <Page404 />,
        children: [
          {
            path: 'profile',
            element: (
              <Page title="Profile">
                <UserProfile />
              </Page>
            ),
          },
          {
            path: 'settings',
            element: (
              <Page title="Settings">
                <UserSetting />
              </Page>
            ),
          },
        ],
      },
    ],
  },
]);
