import { Typography } from 'components/common/Typography/Typography';

type RxInfoProps = {
  prescriptionDetails: {
    name: string;
    refills?: number;
    strength?: string;
    dtd?: string;
    ratio?: string;
    dose?: string;
    price?: string;
  };
  showDivider?: boolean;
  isRefillOrder?: boolean;
  isShipping?: boolean;
};
export function RxInfo({
  prescriptionDetails,
  showDivider,
  isRefillOrder = false,
  isShipping = false,
}: RxInfoProps) {
  return (
    <div className="mt-2">
      <div className="flex justify-between">
        <Typography variant="subtitle2" color="primary">
          {prescriptionDetails.name}
        </Typography>
        <div
          className={`flex ${
            isRefillOrder ? 'justify-between' : 'justify-end'
          } w-1/2`}>
          {isRefillOrder ? (
            <Typography variant="subtitle3">
              Refill(s): {prescriptionDetails.refills || 0}
            </Typography>
          ) : null}
        </div>
      </div>
      {!isShipping ? (
        <div className="mt-1 flex justify-between">
          <div className="w-1/2">
            <Typography variant="subtitle3">
              Quantity: {prescriptionDetails.dtd || 'NA'}
            </Typography>
            <Typography variant="subtitle3">
              Dose: {prescriptionDetails.dose || 'NA'}
            </Typography>
          </div>

          <div className="w-1/2">
            <Typography variant="subtitle3">
              Ratio: {prescriptionDetails.ratio || 'NA'}
            </Typography>

            <Typography variant="subtitle3">
              Strength: {prescriptionDetails.strength || 'NA'}
            </Typography>
          </div>
        </div>
      ) : null}

      {showDivider ? <div className="mt-4 border-b border-gray-400" /> : null}
    </div>
  );
}
