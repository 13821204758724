import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from 'constants/query-keys';

import { AddCard } from '../AddCard/AddCard';

type CardDetailsFormProps = {
  clientSecret: string;
  amount?: number;
  onCardAdded: () => void;
  patientDetails: { name: string; email: string };
  onModalClose?: () => void;
};

export function CardDetailsForm({
  clientSecret,
  amount,
  onCardAdded,
  patientDetails,
  onModalClose,
}: CardDetailsFormProps) {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  );
  const queryClient = useQueryClient();
  const { id } = useParams();

  const handleCardAddition = async () => {
    await queryClient.refetchQueries(
      QueryKeys.PatientPrescription.listing({ id })
    );
    onCardAdded();
  };

  return (
    <div className="mt-4 ">
      <Elements stripe={stripePromise}>
        <AddCard
          onCardAdded={handleCardAddition}
          patientDetails={patientDetails}
          subscriptionDetails={{ clientSecret, amount }}
          onModalClose={onModalClose}
        />
      </Elements>
    </div>
  );
}
