import React from 'react';

import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';

type TooltipProps = {
  children?: React.ReactNode;
  id: string;
} & ITooltip;

const style = {
  background: 'white',
  color: 'black',
  fontFamily: 'Verdana',
};

export function Tooltip({ children, id, ...rest }: TooltipProps) {
  return (
    <ReactTooltip id={id} style={style} {...rest}>
      {children}
    </ReactTooltip>
  );
}
