import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { formatPhoneNumber, getReadableDate } from 'utils/common';

type PatientInfoProps = {
  details: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    pictureUrl: string;
    phone: string;
    patientDetails: {
      id: string;
      gender: string;
      dob: string;
      isMarried: string;
      emergencyContactName: string;
      emergencyContactNumber: string;
    };
    practice: {
      id: string;
      name: string;
      logoUrl: string;
    };
    patientsProvider: {
      id: string;
      name: string;
      phone: string;
    };
    patientCompletedVisits: number;
    createdAt: string;
  };
};

export function PatientInfo({ details }: PatientInfoProps) {
  return (
    <div className="mt-4 rounded-t-md bg-background-main px-7   py-4 text-base text-background-contrastText shadow-sm">
      <div className="grid grid-cols-12">
        <div className="col-span-6 flex">
          <Avatar
            imgUrl={details.pictureUrl ?? undefined}
            userName={`${details.firstName} ${details.lastName}`}
            sizeClass="h-20 w-20"
          />
          <div className="ml-6 flex flex-col justify-center gap-2">
            <Typography variant="subtitle1" className="capitalize">
              {`${details.firstName} ${details.lastName}`} (
              {details.patientDetails.gender})
            </Typography>
            <Typography variant="subtitle1">{details.email}</Typography>
            <Typography variant="subtitle1">
              {formatPhoneNumber(details.phone)}
            </Typography>
          </div>
        </div>
        <div className="col-span-6 mt-1 flex">
          <div className="flex  flex-col gap-2 font-medium">
            <Typography variant="subtitle1">DOB</Typography>
            <Typography variant="subtitle1">Completed Visits</Typography>
            <Typography variant="subtitle1">Patient ID</Typography>
          </div>
          <div className="mx-6 flex flex-col gap-2 font-semibold">
            <Typography variant="subtitle1">:</Typography>
            <Typography variant="subtitle1">:</Typography>
            <Typography variant="subtitle1">:</Typography>
          </div>
          <div className="flex  flex-col gap-2">
            <Typography variant="subtitle1">
              {getReadableDate(details.patientDetails.dob)}
            </Typography>
            <Typography variant="subtitle1">
              {details.patientCompletedVisits || 0}
            </Typography>
            <Typography variant="subtitle1">{details.id}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
