/* eslint-disable camelcase */
import { APIService } from 'services/api-service';

import { UserServiceNS } from './types';

export class UserService extends APIService {
  static GET_USER_STATS = `${this.API_PREFIX_URL}/users/stats`;
  static getUserStats = async (id?: string) => {
    const url = new URL(this.GET_USER_STATS);
    if (id) {
      url.searchParams.append('practice_id', id as string);
    }

    return this.get<UserServiceNS.GetUserStatsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_ADMINS = `${this.API_PREFIX_URL}/users/`;
  static getAdmins = async ({ page, search }: UserServiceNS.GetAdminBody) => {
    const url = new URL(`${this.GET_ADMINS}`);
    url.searchParams.append('user_type', 'Admin');
    if (search) {
      url.searchParams.append('search', search);
    }
    if (page) {
      url.searchParams.append('page', String(page));
    }
    url.searchParams.append('size', '10');

    return this.get<UserServiceNS.GetAdminResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_PRESCRIPTIONS = `${this.API_PREFIX_URL}/users/prescriptions?size=10`;
  static getPatientPrescriptions = async ({
    page,
    search,
    category,
  }: UserServiceNS.GetPatientPrescriptionsBody) => {
    const url = new URL(this.GET_PATIENT_PRESCRIPTIONS);
    url.searchParams.append('page', String(page));
    url.searchParams.append('category', String(category));
    url.searchParams.append('status', 'Pending Review');
    if (search) {
      url.searchParams.append('search', String(search || ''));
    }

    return this.get<UserServiceNS.GetPrescriptionsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENTS = `${this.API_PREFIX_URL}/users/`;
  static getPatients = async ({
    page,
    search,
  }: UserServiceNS.GetPatientsBody) => {
    const url = new URL(`${this.GET_PATIENTS}`);
    url.searchParams.append('user_type', 'Patient');
    if (search) {
      url.searchParams.append('search', search);
    }
    if (page) {
      url.searchParams.append('page', String(page));
    }
    url.searchParams.append('size', '10');

    return this.get<UserServiceNS.GetPatientsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_DETAILS = `${this.API_PREFIX_URL}/users/`;
  static getPatientDetails = async (id: string) => {
    const url = new URL(`${this.GET_PATIENT_DETAILS}${id}`);

    return this.get<UserServiceNS.PatientInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PROVIDERS = `${this.API_PREFIX_URL}/users/`;
  static getProviders = async ({
    page,
    search,
  }: UserServiceNS.GetProviderBody) => {
    const url = new URL(this.GET_PROVIDERS);
    url.searchParams.append('user_type', 'Provider');
    if (search) {
      url.searchParams.append('search', search);
    }
    if (page) {
      url.searchParams.append('page', String(page));
    }
    url.searchParams.append('size', '10');

    return this.get<UserServiceNS.GetProviderResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PROVIDER_DETAILS = `${this.API_PREFIX_URL}/users/`;
  static getProviderDetails = async (id: string) => {
    const url = new URL(`${this.GET_PROVIDER_DETAILS}${id}`);

    return this.get<UserServiceNS.ProviderInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static STATES = `${this.API_PREFIX_URL}/profile/states`;
  static getStates = async () =>
    this.get<UserServiceNS.GetStates>(UserService.STATES, {
      withAuthToken: true,
    });
}
