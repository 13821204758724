import React from 'react';

import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';
import { formatPhoneNumber } from 'utils/common';

export function ProviderOverview() {
  const { id } = useParams();

  const getProvidersQuery = useQuery(
    QueryKeys.ProviderDetails.item(id || ''),
    () => UserService.getProviderDetails(id || ''),
    { enabled: !!id }
  );

  return getProvidersQuery.isSuccess ? (
    <div className="mt-4 rounded-t-md bg-background-main px-7   py-4 text-base text-background-contrastText shadow-sm">
      <div className="grid grid-cols-12">
        <div className="col-span-6 flex">
          <Avatar
            imgUrl={getProvidersQuery.data.data.pictureUrl ?? undefined}
            userName={
              getProvidersQuery.data.data.firstName +
              getProvidersQuery.data.data.lastName
            }
            sizeClass="h-20 w-20"
          />
          <div className="ml-10 flex flex-col justify-center gap-3">
            <Typography variant="subtitle2" className="capitalize">
              {getProvidersQuery.data.data.firstName}{' '}
              {getProvidersQuery.data.data.lastName}
            </Typography>
            <Typography variant="subtitle2">
              {getProvidersQuery.data.data.email}
            </Typography>
            <Typography variant="subtitle2">
              {formatPhoneNumber(getProvidersQuery.data.data.phone) ?? 'NA'}
            </Typography>
            <Typography variant="subtitle2">
              Office Manager Name :{' '}
              {getProvidersQuery.data.data.providerDetails.officeManagerName}
            </Typography>
          </div>
        </div>
        <div className="flex text-base">
          <div className="flex  flex-col gap-3 font-medium">
            <Typography variant="subtitle2">DEA</Typography>
            <Typography variant="subtitle2">NPI</Typography>
            <Typography className="w-max" variant="subtitle2">
              Office Manager Email
            </Typography>
          </div>
          <div className="mx-6 flex flex-col gap-3 font-semibold">
            <Typography variant="subtitle2">:</Typography>
            <Typography variant="subtitle2">:</Typography>
            <Typography variant="subtitle2">:</Typography>
          </div>
          <div className="flex flex-col gap-3">
            <Typography variant="subtitle2">
              {getProvidersQuery.data.data.providerDetails.dea}
            </Typography>
            <Typography variant="subtitle2">
              {getProvidersQuery.data.data.providerDetails.npi}
            </Typography>
            <Typography variant="subtitle2">
              {getProvidersQuery.data.data.providerDetails.officeManagerEmail}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-[max-content_20rem_30rem] place-content-start items-center gap-x-10 rounded-md bg-background-main p-5">
      <Skeleton circle width={100} height={100} />
      <Skeleton inline containerClassName="grid gap-y-5 pr-[4rem]" count={4} />
      <Skeleton inline containerClassName="grid gap-y-5 px-[4rem]" count={4} />
      <div />
    </div>
  );
}
