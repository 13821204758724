import React from 'react';

import { NavLink, Outlet } from 'react-router-dom';

const tabs = [
  { title: 'Active', link: 'active-providers' },
  { title: 'Invitations', link: 'invited-providers' },
];

export function ProviderLayout() {
  return (
    <div className="ml-4 mt-5">
      <div className="mt-14 flex">
        <div>
          <div className="mt-2 flex cursor-pointer flex-col text-base font-semibold text-background-contrastText">
            {tabs.map((tab) => (
              <NavLink
                to={tab.link}
                className={({ isActive }) =>
                  `inline-block py-4  pl-1 text-md  ${
                    isActive
                      ? 'text-primary-main '
                      : 'text-background-contrastText '
                  }`
                }>
                {tab.title}
              </NavLink>
            ))}
          </div>
        </div>
        <span className="mx-8 h-[8rem] w-[2px] bg-background-light" />
        <div className="grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
