import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { emailSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { ProfileService } from 'services/profile';

type UpdateEmailProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
  email: string;
};
export function UpdateEmail({ modal, closeModal, email }: UpdateEmailProps) {
  const updateEmailMutation = useMutationWithToast(ProfileService.updateEmail);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  const onSubmit = handleSubmit((data) => {
    updateEmailMutation.mutate(
      { email: data.email },
      {
        onSuccess: () => {
          closeModal(false);
          reset();
        },
      }
    );
  });

  return (
    <div>
      <Modal
        title="Change Email "
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <TextInput
            className="w-4/5 pl-2"
            type="text"
            name="email"
            fullWidth
            control={control}
            error={
              extractValidationErrors('email', updateEmailMutation, errors)
                .hasError
            }
            helperText={
              extractValidationErrors('email', updateEmailMutation, errors).msg
            }
            defaultValue={email}
            placeholder="Enter email"
          />

          <div>
            <div className="mt-2 mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  reset();
                  closeModal(false);
                }}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updateEmailMutation.isLoading}
                onClick={onSubmit}>
                Update Email
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
