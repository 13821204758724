import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { QueryKeys } from 'constants/query-keys';
import { phoneSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { ProfileService } from 'services/profile';

type UpdatePhoneProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
  phone: string;
};
export function UpdatePhone({ modal, closeModal, phone }: UpdatePhoneProps) {
  const queryClient = useQueryClient();
  const updatePhoneMutation = useMutationWithToast(
    ProfileService.updatePhoneNumber
  );
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(phoneSchema),
    defaultValues: {
      phone: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    updatePhoneMutation.mutate(
      { phone: `+${data.phone}` },
      {
        onSuccess: () => {
          closeModal(false);
          queryClient.refetchQueries([QueryKeys.UserProfile]);
          setTimeout(reset, 200);
        },
      }
    );
  });

  return (
    <div>
      <Modal
        title="Change Phone number"
        open={modal}
        className="w-2/5"
        closeModal={closeModal}>
        <div className="mt-4">
          <TextInput
            className="w-full"
            name="phone"
            control={control}
            helperText={
              extractValidationErrors('phone', updatePhoneMutation, errors).msg
            }
            error={
              extractValidationErrors('phone', updatePhoneMutation, errors)
                .hasError
            }
            defaultValue={phone}
            placeholder="Phone"
          />
          <div>
            <div className="mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setTimeout(reset, 200);
                  closeModal(false);
                }}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updatePhoneMutation.isLoading}
                onClick={onSubmit}>
                Update Phone
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
