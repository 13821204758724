import classNames from 'classnames';

interface RadioButtonProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

export function RadioButton({
  id,
  onChange,
  className,
  label,
  checked,
}: RadioButtonProps) {
  return (
    <div className={classNames('flex items-center text-base  ', className)}>
      <input
        className="cursor-pointer accent-primary-main "
        id={id}
        onChange={(e) => onChange(e.target.checked)}
        type="radio"
        checked={checked}
      />
      <span className="mr-1" />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
