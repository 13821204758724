import { APIService } from 'services/api-service';

import { BillingServicesNS } from './types';

export class BillingService extends APIService {
  static GET_ACTIVE_SUBSCRIPTION = `${this.API_PREFIX_URL}/billing/subscription`;
  static getActiveSubscription = async (patientId: string) => {
    const url = new URL(this.GET_ACTIVE_SUBSCRIPTION);
    url.searchParams.append('patient_id', patientId);

    return this.get<BillingServicesNS.SubscriptionInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_BALANCE = `${this.API_PREFIX_URL}/billing/balance`;
  static getCreditBalance = async (patientId: string) => {
    const url = new URL(this.GET_BALANCE);
    url.searchParams.append('patient_id', patientId);

    return this.get<BillingServicesNS.BalanceInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_SAVED_CARDS = `${this.API_PREFIX_URL}/billing/cards`;
  static getSavedCards = async (patientId: string) => {
    const url = new URL(this.GET_SAVED_CARDS);
    url.searchParams.append('patient_id', patientId);

    return this.get<BillingServicesNS.PaymentMethod[]>(url.toString(), {
      withAuthToken: true,
    });
  };

  static SET_DEFAULT_PAYMENTMETHOD = `${this.API_PREFIX_URL}/billing/set-default-payment-method`;
  static setDefaultPaymentMethod = async ({
    patientId,
    paymentMethodId,
  }: BillingServicesNS.SetDefaultPaymentMethodBody) => {
    const url = new URL(this.SET_DEFAULT_PAYMENTMETHOD);
    url.searchParams.append('patient_id', patientId);

    return this.post(
      url.toString(),
      {
        payment_method_id: paymentMethodId,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static CANCEL_ACTIVE_SUBSCRIPTION = `${this.API_PREFIX_URL}/billing/cancel-subscription`;
  static cancelActiveSubscription = async (patientId: string) => {
    const url = new URL(this.CANCEL_ACTIVE_SUBSCRIPTION);
    url.searchParams.append('patient_id', patientId);

    return this.post(
      url.toString(),
      {},
      {
        withAuthToken: true,
      }
    );
  };

  static BILLING_PLANS = `${this.API_PREFIX_URL}/billing/plans/v1`;
  static getBillingPlans = async (id: string) =>
    this.get<BillingServicesNS.GetBillingPlansResponse>(
      `${BillingService.BILLING_PLANS}?patient_id=${id}`,
      { withAuthToken: true }
    );

  static CALCULATE_PRORATION = `${this.API_PREFIX_URL}/billing/proration`;
  static calculateProrationAmount = ({
    priceId,
    prorationDate = Math.floor(Date.now() / 1000),
    id,
  }: BillingServicesNS.CalculateProrationBody) =>
    this.get<BillingServicesNS.CalculateProrationResponse>(
      `${BillingService.CALCULATE_PRORATION}?patient_id=${id}`,
      {
        params: {
          price_id: priceId,
          proration_date: prorationDate,
        },
        withAuthToken: true,
      }
    );

  static CREATE_AND_UPDATE_SUBSCRIPTION = `${this.API_PREFIX_URL}/billing/subscription`;
  static createSubscription = ({
    recurring,
    oneTime,
    id,
    coupon,
  }: BillingServicesNS.CreateSubscriptionBody) =>
    this.post<BillingServicesNS.CreateSubscriptionResponse>(
      `${BillingService.CREATE_AND_UPDATE_SUBSCRIPTION}?patient_id=${id}`,
      {
        recurring,
        oneTime,
        coupon,
      },
      { withAuthToken: true }
    );

  static updateSubscription = ({
    recurring,
    oneTime,
    id,
  }: BillingServicesNS.CreateSubscriptionBody) =>
    this.put<BillingServicesNS.CreateSubscriptionResponse>(
      `${BillingService.CREATE_AND_UPDATE_SUBSCRIPTION}?patient_id=${id}`,
      {
        ...(recurring ? { recurring } : {}),
        oneTime,
      },
      { withAuthToken: true }
    );

  static SETUP_INTENT = `${this.API_PREFIX_URL}/billing/setup-intent`;
  static getSetUpIntent = async (patientId: string) => {
    const url = new URL(this.SETUP_INTENT);
    url.searchParams.append('patient_id', patientId);

    return this.get<BillingServicesNS.GetSetUpIntent>(url.toString(), {
      withAuthToken: true,
    });
  };
}
