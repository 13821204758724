import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { OrderInfo } from 'types/orders/index';

import { RxInfo } from './RxInfo/RxInfo';

type OrderDetailsProps = {
  orderDetails: OrderInfo;
};

export function convertToSentenceCase(str: string) {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

export function OrderDetails({ orderDetails }: OrderDetailsProps) {
  const handlePdfDownload = async (pdfUrl: string) => {
    const fileName = 'Invoice.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="w-full">
      <div className="w-full">
        {orderDetails?.orderType === 'new' ? (
          <>
            {orderDetails?.orderSubscription?.orderItems.length ? (
              <div className="">
                <Typography variant="h3">Subscription Items</Typography>

                <div className="mt-2 w-full">
                  <div className="mb-2 flex w-full justify-between">
                    <Typography color="primary" variant="h5">
                      {orderDetails.orderSubscription.subscriptionPackage.name}
                    </Typography>
                  </div>
                  <div className="ml-8">
                    {orderDetails?.orderSubscription?.orderItems.map(
                      (item, index) => (
                        // eslint-disable-next-line react/jsx-indent
                        <RxInfo
                          showDivider={
                            index !==
                            orderDetails.orderSubscription.orderItems.length - 1
                          }
                          prescriptionDetails={{
                            name: item.prescription.prescriptionToMedication
                              .name,
                            refills: item.prescription.refills,
                            strength: `${
                              item.prescription.strength.value || ''
                            }${item.prescription.strength.unit || ''}`,
                            ratio: `${item.prescription.ratio?.e2 || ''}${
                              item.prescription?.ratio?.e2 ? ':' : ''
                            }${item.prescription.ratio?.e3 || ''}`,
                            dtd: `${item.prescription.dtd.value || ''}${
                              item.prescription.dtd.unit || ''
                            }`,
                            dose: `${
                              item.prescription.deliveryMethodAndSig.dose
                                .value || ''
                            }${
                              item.prescription.deliveryMethodAndSig.dose
                                .unit || ''
                            }`,
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {orderDetails.orderSubscription?.orderItems.length ? (
              <span className="mt-3 mb-3 block h-[0.06px] w-full bg-zinc-500" />
            ) : null}

            {orderDetails?.orderNonSubscriptionItems?.length ? (
              <div>
                <Typography variant="h3">Non-Subscription Items</Typography>

                <div className="mt-2 ml-8">
                  {orderDetails?.orderNonSubscriptionItems.map(
                    (item, index) => (
                      // eslint-disable-next-line react/jsx-indent
                      <RxInfo
                        key={item.prescription.prescriptionToMedication.id}
                        showDivider={
                          index !==
                          orderDetails.orderNonSubscriptionItems.length - 1
                        }
                        prescriptionDetails={{
                          name: item.prescription.prescriptionToMedication.name,
                          refills: item.prescription.refills,
                          price: item.paidAmount || '',
                          strength: `${item.prescription.strength.value || ''}${
                            item.prescription.strength.unit || ''
                          }`,
                          ratio: `${item.prescription?.ratio?.e2 || ''}${
                            item.prescription?.ratio?.e2 ? ':' : ''
                          }${item.prescription.ratio?.e3 || ''}`,
                          dtd: `${item.prescription.dtd.value || ''}${
                            item.prescription.dtd.unit || ''
                          }`,
                          dose: `${
                            item.prescription.deliveryMethodAndSig.dose.value ||
                            ''
                          }${
                            item.prescription.deliveryMethodAndSig.dose.unit ||
                            ''
                          }`,
                        }}
                      />
                    )
                  )}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div>
            <Typography variant="h3">Refill(s)</Typography>

            <div>
              {orderDetails?.orderItems.map((item, index) => (
                // eslint-disable-next-line react/jsx-indent
                <RxInfo
                  key={item.prescription.prescriptionToMedication.id}
                  isRefillOrder
                  showDivider={index !== orderDetails.orderItems.length - 1}
                  prescriptionDetails={{
                    name: item.prescription.prescriptionToMedication.name,
                    refills: 1,
                    price: item.paidAmount || '',
                    strength: `${item.prescription.strength.value || ''}${
                      item.prescription.strength.unit || ''
                    }`,
                    ratio: `${item.prescription.ratio?.e2 || ''}${
                      item.prescription?.ratio?.e2 ? ':' : ''
                    }${item.prescription.ratio?.e3 || ''}`,
                    dtd: `${item.prescription.dtd.value || ''}${
                      item.prescription.dtd.unit || ''
                    }`,
                    dose: `${
                      item.prescription.deliveryMethodAndSig.dose.value || ''
                    }${item.prescription.deliveryMethodAndSig.dose.unit || ''}`,
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {orderDetails.orderNonSubscriptionItems?.length ||
        orderDetails.orderItems.length ? (
          <span className="mt-3 mb-3 block h-[0.06px] w-full bg-zinc-500" />
        ) : null}

        <div>
          <Typography variant="h3">Shipping Type</Typography>

          <div>
            <RxInfo
              isShipping
              prescriptionDetails={{
                name: convertToSentenceCase(
                  orderDetails?.preferredShipping?.name
                ),
                price: orderDetails?.preferredShipping?.price,
              }}
            />
          </div>
        </div>

        <span className="mt-3 mb-3 block h-[0.06px] w-full bg-zinc-500" />

        <div className="mt-4 mb-3 flex w-full justify-end" />
      </div>

      <div className="mb-1 flex items-start justify-end ">
        <div className="flex flex-col justify-end space-y-2">
          <Button
            onClick={() => handlePdfDownload(orderDetails.invoicePdf)}
            disabled={orderDetails.status !== 'completed'}
            className="ml-auto w-max">
            Download Invoice
          </Button>
          <Typography className="ml-0.5" variant="subtitle3">
            Invoice will be available to download once the products are
            delivered.
          </Typography>
        </div>
      </div>
    </div>
  );
}
