import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { resetPasswordSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { ProfileService } from 'services/profile';

export function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordMutation = useMutationWithToast(
    ProfileService.resetPassword,
    {}
  );
  const token = searchParams.get('token');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit((data) => {
    resetPasswordMutation.mutate(
      { password: data.password, token: token ?? '' },
      {
        onSuccess: () => {
          navigate({ pathname: '/auth/login' });
        },
      }
    );
  });

  return (
    <form
      onSubmit={onSubmit}
      className=" grid w-full place-items-center rounded-sm px-8 py-10 pb-8">
      <h1 className="mb-8 text-white">Password Reset</h1>

      <TextInput
        className="pl-2"
        type="password"
        name="password"
        control={control}
        helperText={errors.password?.message?.toString()}
        error={!!errors.password?.message}
        placeholder="New Password"
        fullWidth
      />
      <TextInput
        className="pl-2"
        type="password"
        name="confirmPassword"
        control={control}
        helperText={errors.confirmPassword?.message?.toString()}
        error={!!errors.confirmPassword?.message}
        placeholder="Confirm Password"
        fullWidth
      />
      <div className="row mt-2 flex w-full items-center justify-end">
        <Button type="submit" loading={resetPasswordMutation.isLoading}>
          Reset
          <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
        </Button>
      </div>
    </form>
  );
}
