import { Prescription } from 'types/prescriptionMedication';

export enum GENDER {
  Male = 'M',
  Female = 'F',
}

export enum BILLING_CYCLES {
  Yearly = 'annual',
  Monthly = 'monthly',
}

export enum PRODUCT_USAGES_TYPE {
  Licensed = 'licensed',
}

export enum PRODUCT_RECURRING_INTERVAL {
  Year = 'year',
  Month = 'month',
}

export enum PRODUCT_TYPE {
  Recurring = 'recurring',
  OneTime = 'one_time',
}

export enum SHIPPING_PRODUCT_TYPE {
  Shipping = 'shipping',
}
export enum SHIPPING_PRODUCT_PAYMENT_TYPE {
  ONE_TIME = 'one-time',
}

export enum CURRENCY {
  USD = 'usd',
}

export enum PRODUCT_SUBSCRIPTION_STATUS {
  Active = 'active',
}

export type SubscriptionProductMetadata = {
  name: string;
  stripeProductId: string;
  stripePriceId: string;
  count: string;
  gender: GENDER;
  billingCycle: BILLING_CYCLES;
  paymentType: SHIPPING_PRODUCT_TYPE;
};
export type IndividualProductMetadata = {
  quantity: number;
};
export type ShippingProductMetadata = {
  gender: GENDER;
  paymentType: SHIPPING_PRODUCT_PAYMENT_TYPE;
  type: SHIPPING_PRODUCT_TYPE;
};

export type ProductDefaultPrice =
  | {
      id: string;
      currency: CURRENCY;
      unitAmount: number;
      unitAmountDecimal: string;
      product: string;
    } & (
      | {
          type: PRODUCT_TYPE.Recurring;
          recurring: {
            interval: PRODUCT_RECURRING_INTERVAL;
            intervalCount: number;
            trialPeriodDays: null;
            usageType: PRODUCT_USAGES_TYPE;
            aggregateUsage: null;
          };
        }
      | {
          type: PRODUCT_TYPE.OneTime;
          recurring: null;
        }
    );

export type IndividualProducts = Prescription & { product: Product };

export type Product = {
  id: string;
  stripeProductId: string;
  stripePriceId: string;
  paymentType?: string;
  gender: GENDER;
  dose: string;
  dtd: number;
  productType?: string;
  strength: string;
  amount: string;
  isActive: boolean | string;
  productMetadata?: {
    shippingType: string;
  };
  medication?: {
    name: string;
  };
  count?: string;
  billingCycle?: BILLING_CYCLES;
  name?: string;
};

export type UpcomingInvoice = {
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  billingReason: string;
  created: number;
  currency: CURRENCY;
  dueDate: null;
  nextPaymentAttempt: number;
  periodStart: number;
  periodEnd: number;
  status: PRODUCT_SUBSCRIPTION_STATUS;
  total: number;
};

export type SubscriptionPackage = {
  id: string;
  name: string;
  stripeProductId: string;
  stripePriceId: string;
  amount: string;
  count: string;
  gender: string;
  billingCycle: string;
  productMetadata: null;
  isActive: boolean;
};

export type Subscription = {
  id: string;
  stripeSubscriptionId: string;
  stripeSubscriptionStatus: string;
  subscriptionPackage: SubscriptionPackage;
  stripeSubscriptionPeriodEnd: string;
};

export type ActiveSubscription = {
  upcomingInvoice: UpcomingInvoice;
  subscription: Subscription;
};

export type Proration = {
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  amountShipping: number;
  subscriptionProrationDate: string;
  subtotal: number;
  subtotalExcludingTax: number;
  credit: number;
  debit: number;
  creditNote: string;
  debitNote: string;
  total: number;
};

export type couponObject = {
  id: string;
  couponName: string;
  percentOff: number;
  redeemBy: string;
  description: string;
};
