import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { BasicInformation } from 'components/onboard/BasicInformation';
import { practiceAdminOnboardSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { OnboardService } from 'services/onboard';

export function PracticeAdminOnboard() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const adminOnboardMutation = useMutationWithToast(
    OnboardService.onBoardPracticeAdmin,
    {}
  );
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(practiceAdminOnboardSchema),
    defaultValues: {
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    adminOnboardMutation.mutate(
      {
        token: token ?? '',
        practiceAdminDetails: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          phone: `+${data.phone}`,
        },
      },
      {
        onSuccess: () => {
          navigate({ pathname: '/auth/login' });
        },
      }
    );
  });

  return (
    <div className="w-full bg-background-dark p-24">
      <div className=" ">
        <Typography variant="h1">
          Register as{' '}
          <Typography className="mx-2" variant="h1" color="primary">
            Practice
          </Typography>{' '}
          Admin
        </Typography>
        <Typography variant="h3" className="mt-2">
          Please provide us with the following information to register yourself.
        </Typography>
      </div>
      <div className="mt-12 grid gap-4">
        <BasicInformation
          control={control}
          errors={errors}
          adminOnboardMutation={adminOnboardMutation}
        />
        <div className="flex justify-end">
          <Button loading={adminOnboardMutation.isLoading} onClick={onSubmit}>
            Register <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
}
