import React, { PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

interface StepperProps {
  steps: string[];
  currentStep: number;
  customStepper?: () => ReactElement;
}

export function Stepper({
  steps,
  children,
  currentStep,
  customStepper,
}: PropsWithChildren<StepperProps>) {
  return (
    <div className="w-full">
      <div className="w-full">
        {customStepper ? (
          customStepper()
        ) : (
          <div className="flex ">
            {steps?.map((step, index, arr) => (
              <div
                className={classNames(
                  ' mr-1 flex w-full flex-col items-center border-b-4  pb-1  ',
                  {
                    '!border-primary-light': index === currentStep,
                    '!mr-0': index === arr.length - 1,
                  }
                )}>
                {' '}
                <div
                  className={classNames(
                    'relative mt-2 flex h-8 w-8 items-center justify-center rounded-full bg-background-light text-md text-background-contrastText 2xl:h-10 2xl:w-10',
                    {
                      '!bg-primary-light !text-primary-contrastText':
                        index === currentStep || index < currentStep,
                    }
                  )}>
                  {index + 1}
                  {/* <div
                    className={classNames(
                      'absolute -right-28 h-1 w-28 bg-background-light',
                      {
                        hidden: index === arr.length - 1,
                        '!bg-primary-light ': index < currentStep,
                      }
                    )}
                  />{' '} */}
                </div>{' '}
                <Typography
                  align="center"
                  className=" mt-2  break-words 2xl:text-md"
                  variant="subtitle3"
                  color={index === currentStep ? 'primary' : 'gray'}>
                  {step}
                </Typography>{' '}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mt-2">
        {Array.isArray(children)
          ? (children as ReactElement[]).find(
              (child) => child?.props?.step === currentStep
            )
          : children}
      </div>
    </div>
  );
}
