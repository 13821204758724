import React, { PropsWithChildren } from 'react';

import { Helmet } from 'react-helmet';

import { useProfile } from 'hooks/useProfile';

import LOGO from '../../../assets/images/LOGO.png';

type PageProps = {
  title?: string;
};
export function Page({ children, title }: PropsWithChildren<PageProps>) {
  const { profile } = useProfile();

  function getFavicon() {
    if (profile?.practice?.logoUrl) {
      return profile?.practice?.logoUrl;
    }

    return LOGO;
  }

  return (
    <div>
      <Helmet>
        <title>
          {title
            ? `${title} |  ${profile?.practice?.name || 'Brite live'}`
            : 'Brite live'}
        </title>
        <link rel="icon" type="image/png" href={getFavicon()} />
      </Helmet>
      <div>{children}</div>
    </div>
  );
}
