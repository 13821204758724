import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { Typography } from 'components/common/Typography/Typography';
import { IndividualProducts } from 'types/payment/types';
import { getPriceInTwoDecimals } from 'utils/price-calculation';
import 'react-loading-skeleton/dist/skeleton.css';

export function IndividualProductsCard({
  individualProducts,
  isLoading = false,
}: {
  individualProducts: IndividualProducts[];
  isLoading: boolean;
}) {
  return (
    <div className="mt-3">
      <div className="mt-2 mb-2 rounded-lg border border-gray-200 bg-white p-2 shadow-sm">
        <Typography variant="body1" className="mb-2">
          Rx(s) not covered under subscription
        </Typography>
        {isLoading ? (
          <>
            <div className="mb-1">
              <div className=" -mb-2 flex justify-between ">
                <Skeleton count={1} width={130} height={12} />{' '}
                <Skeleton width={20} className="ml-3" height={12} />
              </div>
              <Skeleton count={0.3} height={10} />
            </div>
            <div className="mb-1">
              <div className=" -mb-2 flex justify-between ">
                <Skeleton count={1} width={130} height={12} />{' '}
                <Skeleton width={20} className="ml-3" height={12} />
              </div>
              <Skeleton count={0.3} height={10} />
            </div>
          </>
        ) : (
          individualProducts.map((item) => (
            <div key={item.id} className="mb-1.5">
              <div className="align-center flex justify-between">
                <Typography color="primary" variant="subtitle3">
                  {item.prescriptionToMedication.name}{' '}
                </Typography>
                <div className="flex items-center">
                  <Typography variant="body2" color="primary">
                    $
                  </Typography>
                  <Typography variant="body2" color="primary">
                    {getPriceInTwoDecimals(item?.product?.amount)}
                  </Typography>
                </div>
              </div>

              <Typography variant="body2">
                Quantity : {`${item.dtd?.value}${item.dtd?.unit}` || 'NA'}
              </Typography>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
