import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { Typography } from 'components/common/Typography/Typography';
import { OverviewTable } from 'components/patient/overview/OverviewTable';
import { PatientSubscriptionModal } from 'components/patient/overview/PatientSubscriptionModal';
import { QueryKeys } from 'constants/query-keys';
import { useSubscriptionState } from 'hooks/usePatientSubscriptionState';
import { UserService } from 'services/user';

export function PatientOverview() {
  const { id } = useParams();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const subscriptionState = useSubscriptionState();
  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(id || ''),
    () => UserService.getPatientDetails(id || '')
  );

  const patientDetails = patientDetailsQuery.data?.data;

  return (
    <div className="pb-6">
      {id && (
        <div className="mt-2 rounded-b-md p-2">
          <OverviewTable title="Current Rx(s)" id={id} category="current" />
          <div className="mt-12" />
          <OverviewTable
            elementAfterHeading={
              (subscriptionState?.canInitiateFirstSubscription ||
                subscriptionState?.canUpdateSubscription) &&
              patientDetailsQuery.isSuccess ? (
                <>
                  <div data-tooltip-id="make_payment">
                    <Button
                      disabled={!patientDetails?.patientShippingAddress}
                      onClick={() =>
                        !patientDetails?.patientShippingAddress
                          ? setShowInfoModal(true)
                          : setShowPaymentModal(true)
                      }>
                      Make Payment
                    </Button>
                  </div>
                  {!patientDetails?.patientShippingAddress && (
                    <Tooltip className="!-ml-0" id="make_payment">
                      No shipping address found
                    </Tooltip>
                  )}
                </>
              ) : null
            }
            title="Future Rx(s)"
            id={id}
            category="future"
          />
          <div className="mt-12" />
          <OverviewTable title="Past Rx(s)" id={id} category="past" />
        </div>
      )}
      <PatientSubscriptionModal
        showPaymentModal={showPaymentModal}
        setShowPaymentModal={setShowPaymentModal}
      />
      {!patientDetails?.patientShippingAddress && (
        <ConfirmationModal
          isModalOpen={showInfoModal}
          infoContent={
            <Typography variant="h4" className="!inline">
              You can not make this payment, as the patient has not updated
              their shipping address.
            </Typography>
          }
          closeModal={() => {
            setShowInfoModal(false);
          }}
        />
      )}
    </div>
  );
}
