import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import {
  inviteProviderSchema,
  resentInviteProviderSchema,
} from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { InviteService } from 'services/invite';

type InviteProviderProps = {
  openModal: boolean;
  onModalClose: () => void;
  onInviteResendSuccess: () => void;
  resendInviteData?: {
    id: string;
    email: string;
  };
};

export function InviteProviderModal({
  openModal,
  onInviteResendSuccess,
  onModalClose,
  resendInviteData,
}: InviteProviderProps) {
  const queryClient = useQueryClient();
  const inviteMutation = useMutationWithToast(InviteService.InviteProvider, {});
  const resendInviteMutation = useMutationWithToast(
    InviteService.resendProviderInvite
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      resendInviteData ? resentInviteProviderSchema : inviteProviderSchema
    ),
  });

  useEffect(() => {
    if (resendInviteData?.email) {
      setValue('email', resendInviteData?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendInviteData]);

  const onSubmit = handleSubmit((data) => {
    if (resendInviteData) {
      resendInviteMutation.mutate(
        { name: data.name, id: resendInviteData?.id },
        {
          onSuccess: () => {
            reset();
            onInviteResendSuccess();
            queryClient.refetchQueries(QueryKeys.InvitedProviders.listings());
          },
        }
      );

      return;
    }

    inviteMutation.mutate(
      {
        email: data.email,
        name: data.name,
        practice: data.practice,
        addressId: data.address,
      },
      {
        onSuccess: () => {
          reset();
          onInviteResendSuccess();
          queryClient.refetchQueries(QueryKeys.InvitedProviders.listings());
        },
      }
    );
  });

  const onCloseClick = () => {
    if (inviteMutation.isLoading || resendInviteMutation.isLoading) return;
    onModalClose();
    reset();
    inviteMutation.reset();
    resendInviteMutation.reset();
  };

  return (
    <div>
      <Modal
        title={resendInviteData ? 'Resend Invitation' : 'Invite Provider'}
        open={openModal}
        className="w-2/5 overflow-visible"
        closeModal={() =>
          inviteMutation.isLoading || resendInviteMutation.isLoading
            ? null
            : onModalClose()
        }>
        <div className="mt-4">
          {!resendInviteData ? (
            <Typography variant="subtitle3" className="mb-4">
              Please provide the email of the provider you want to invite.
            </Typography>
          ) : (
            <p className="mb-4 text-base leading-relaxed">
              Please provide the name of the provider to resend the invite. An
              invite will be sent at
              <span className="inline pl-2 text-primary-main">
                {resendInviteData.email}
              </span>
              .
            </p>
          )}
          <TextInput
            className="w-[80%] pl-2"
            type="text"
            name="name"
            control={control}
            helperText={
              extractValidationErrors(
                'name',
                resendInviteData ? resendInviteMutation : inviteMutation,
                errors
              ).msg
            }
            error={
              extractValidationErrors(
                'name',
                resendInviteData ? resendInviteMutation : inviteMutation,
                errors
              ).hasError
            }
            fullWidth
            placeholder="Name"
          />
          {!resendInviteData && (
            <TextInput
              className="mt-2 w-[80%] pl-2"
              fullWidth
              type="text"
              name="email"
              control={control}
              helperText={
                extractValidationErrors('email', inviteMutation, errors).msg
              }
              error={
                extractValidationErrors('email', inviteMutation, errors)
                  .hasError
              }
              placeholder="Email"
            />
          )}
          {!resendInviteData ? (
            <Typography variant="subtitle3" className="mt-2">
              If provider does not receive the invitation, you may resend from
              your provider dashboard. Double-check email address before
              resubmitting.
            </Typography>
          ) : null}

          <div>
            <div className="mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                disabled={
                  inviteMutation.isLoading || resendInviteMutation.isLoading
                }
                onClick={onCloseClick}>
                Close
              </Button>{' '}
              <span className="mx-2" />
              <Button
                onClick={onSubmit}
                loading={
                  inviteMutation.isLoading || resendInviteMutation.isLoading
                }>
                {resendInviteData ? 'Resend Invite' : 'Send Invite'}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
