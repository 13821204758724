import React, { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { Table } from 'components/common/Table/Table';
import { InviteProviderModal } from 'components/provider/InviteProviderModal/InviteProviderModal';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { InviteService } from 'services/invite';
import { getReadableDate } from 'utils/common';

export function InvitedProviders() {
  const [openInvitePatientModal, setOpenInviteProviderModal] = useState(false);
  const [page, setPage] = useState<number>(1);

  const queryClient = useQueryClient();

  const [resendInvite, setResendInvite] = useState<
    { id: string; email: string } | undefined
  >();

  const getInvitedProvidersQuery = useQuery(
    QueryKeys.InvitedProviders.listing({ page }),
    () => InviteService.getInvitedProviders({ page })
  );

  const deleteInviteMutation = useMutationWithToast(
    InviteService.deleteProviderInvite
  );

  function deleteInvite(id: string) {
    deleteInviteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          queryClient.refetchQueries(QueryKeys.InvitedProviders.listings());
        },
      }
    );
  }

  const { data: invitedProviders } = getInvitedProvidersQuery;

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="grid">
      <div className="mb-3 ml-auto flex items-center  justify-between">
        <Button
          className="ml-auto"
          onClick={() => setOpenInviteProviderModal(true)}>
          Invite Provider
        </Button>
      </div>
      <Table
        loading={getInvitedProvidersQuery?.isLoading}
        rowsData={invitedProviders?.data?.items || []}
        columns={[
          {
            title: 'Id',
            render: (rowData) => rowData.id,
          },
          {
            title: 'Email',
            render: (rowData) => rowData.email,
          },
          {
            title: 'Date',
            render: (rowData) => getReadableDate(rowData.createdAt),
          },
          {
            title: '',
            render: (rowData) => (
              <div className="flex justify-end">
                <Button
                  className="!text-base"
                  size="small"
                  onClick={() => {
                    setOpenInviteProviderModal(true);
                    setResendInvite({ id: rowData.id, email: rowData.email });
                  }}>
                  Resend
                </Button>
                <span className="mx-2" />
                <Button
                  className="!text-base"
                  loading={
                    deleteInviteMutation.isLoading &&
                    deleteInviteMutation.variables?.id === rowData.id
                  }
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => deleteInvite(rowData.id)}>
                  Delete
                </Button>
              </div>
            ),
            skeletonClassNames: 'flex justify-end',
          },
        ]}
        noDataMessage="You currently have no invited providers"
        pagination
        onPageChange={onPageChange}
        page={page}
        totalRecords={invitedProviders?.data?.total || 0}
        recordsPerPage={10}
      />
      <InviteProviderModal
        openModal={openInvitePatientModal}
        resendInviteData={resendInvite}
        onModalClose={() => {
          setOpenInviteProviderModal(false);
          setTimeout(() => setResendInvite(undefined), 300);
        }}
        onInviteResendSuccess={() => {
          setOpenInviteProviderModal(false);
          setTimeout(() => setResendInvite(undefined), 300);
        }}
      />
    </div>
  );
}
