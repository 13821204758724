import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { BiArrowBack } from 'react-icons/bi';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { Typography } from 'components/common/Typography/Typography';
import { OrderDetails } from 'components/prescription-and-orders/OrderDetails/OrderDetails';
import { QueryKeys } from 'constants/query-keys';
import { OrderService } from 'services/order';
import { UserService } from 'services/user';
import { OrderInfo } from 'types/orders';
import { formatPhoneNumber, getReadableDate } from 'utils/common';

enum Gender {
  F = 'Female',
  M = 'Male',
}

export function OrderOverview() {
  const {
    state: { patientId },
  } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const patientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(patientId || ''),
    () => UserService.getPatientDetails(patientId || '')
  );

  const orderQuery = useQuery(QueryKeys.Orders.item(id as string), () =>
    OrderService.getOrder(id || '')
  );

  const getAddressStatement = (address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    state: {
      id: string;
      name: string;
      abbreviation: string;
    };
    zipCode: string;
  }) => {
    if (!address) return 'NA';

    return `${address?.addressLine1}, ${
      address.addressLine2 ? `${address.addressLine2}, ` : ''
    }
            ${address.city || ''}, ${address.state.name || ''}, ${
      address.country || ''
    }, ${address.zipCode || ''}`;
  };

  return (
    <div>
      <Typography className="my-5" variant="h3">
        <BiArrowBack
          className="cursor-pointer pr-3 text-5xl"
          onClick={() => navigate(-1)}
        />{' '}
        Order Details{' '}
      </Typography>
      <div className="grid grow grid-cols-2 gap-5">
        <div className="flex flex-col">
          <Typography className="mb-5" variant="h3">
            Patient Details
          </Typography>
          {!patientDetailsQuery.isSuccess ? (
            <div className="grid grid-cols-2 rounded-md bg-background-main">
              <Skeleton
                containerClassName="p-5 grid grid-cols-[25%_50%] gap-x-6 gap-y-5"
                height={20}
                inline
                count={8}
              />
              <Skeleton
                containerClassName="p-5 grid grid-cols-[25%_50%] gap-x-6 gap-y-5"
                height={20}
                inline
                count={8}
              />
            </div>
          ) : (
            <div className="flex grow flex-col flex-wrap gap-5 rounded-md bg-background-light p-5 xl:max-h-[12rem]">
              <div className="flex items-start">
                <Typography className="min-w-[4rem]" variant="subtitle2">
                  Name
                </Typography>
                <Typography className="w-[1rem]" variant="subtitle2">
                  :
                </Typography>
                <Typography variant="subtitle2">
                  {patientDetailsQuery.data.data.firstName}{' '}
                  {patientDetailsQuery.data.data.lastName}
                </Typography>
              </div>
              <div className="flex items-start">
                <Typography className="min-w-[4rem]" variant="subtitle2">
                  Email
                </Typography>
                <Typography className="w-[1rem]" variant="subtitle2">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="patient_email"
                  className="ellipses-text"
                  variant="subtitle2">
                  {patientDetailsQuery.data.data.email}
                </Typography>
                <Tooltip id="patient_email">
                  {patientDetailsQuery.data.data.email}
                </Tooltip>
              </div>
              <div className="flex items-start">
                <Typography className="min-w-[4rem]" variant="subtitle2">
                  Gender
                </Typography>
                <Typography className="w-[1rem]" variant="subtitle2">
                  :
                </Typography>
                <Typography variant="subtitle2">
                  {
                    Gender[
                      patientDetailsQuery.data.data.patientDetails.gender as
                        | 'F'
                        | 'M'
                    ]
                  }
                </Typography>
              </div>
              <div className="flex items-start">
                <Typography className="min-w-[4rem]" variant="subtitle2">
                  Address
                </Typography>
                <Typography className="w-[1rem]" variant="subtitle2">
                  :
                </Typography>
                <Typography
                  data-tooltip-id="patient_address"
                  className="ellipses-text"
                  variant="subtitle2">
                  {getAddressStatement(
                    patientDetailsQuery.data.data.patientBillingAddress
                  )}
                </Typography>
                <Tooltip id="patient_address">
                  {getAddressStatement(
                    patientDetailsQuery.data.data.patientBillingAddress
                  )}
                </Tooltip>
              </div>
              <div className="flex items-start">
                <Typography
                  className="min-w-[4rem] font-medium"
                  variant="subtitle2">
                  DOB
                </Typography>
                <Typography
                  className="mx-3 flex flex-col gap-5 font-semibold"
                  variant="subtitle2">
                  :
                </Typography>
                <Typography variant="subtitle2">
                  {patientDetailsQuery.data &&
                    getReadableDate(
                      patientDetailsQuery.data.data.patientDetails.dob
                    )}
                </Typography>
              </div>
              <div className="col-span-6 mt-1 flex">
                <div className="min-w-[4rem] font-medium">
                  <Typography variant="subtitle2">Contact</Typography>
                </div>
                <div className="mx-3 flex flex-col gap-5 font-semibold">
                  <Typography variant="subtitle2">:</Typography>
                </div>
                <div className="flex flex-col gap-5 font-medium">
                  <Typography variant="subtitle2">
                    {formatPhoneNumber(patientDetailsQuery.data?.data.phone)}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Typography className="mb-5" variant="h3">
            Provider Details
          </Typography>
          {patientDetailsQuery.status !== 'success' ? (
            <div className="grid grid-cols-2 rounded-md bg-background-main">
              <Skeleton
                containerClassName="p-5 grid grid-cols-[25%_50%] gap-x-6 gap-y-5"
                height={20}
                inline
                count={8}
              />
              <Skeleton
                containerClassName="p-5 grid grid-cols-[25%_50%] gap-x-6 gap-y-5"
                height={20}
                inline
                count={8}
              />
            </div>
          ) : (
            <div className="flex justify-between gap-5 rounded-md bg-background-light p-5">
              <div className="col-span-6 mt-1 flex">
                <div className="flex  flex-col gap-5 font-medium">
                  <Typography variant="subtitle2">Practice Name</Typography>
                  <Typography variant="subtitle2">Office Contact</Typography>
                  <Typography variant="subtitle2">Prescriber</Typography>
                  <Typography variant="subtitle2">NPI</Typography>
                </div>
                <div className="mx-6 flex flex-col gap-5 font-semibold">
                  <Typography variant="subtitle2">:</Typography>
                  <Typography variant="subtitle2">:</Typography>
                  <Typography variant="subtitle2">:</Typography>
                  <Typography variant="subtitle2">:</Typography>
                </div>
                <div className="flex  flex-col gap-5">
                  <Typography className="ellipses-text" variant="subtitle2">
                    {patientDetailsQuery.data.data.practice.name}
                  </Typography>
                  <Typography
                    className="ellipses-text"
                    data-tooltip-id="provider-emergency-contact"
                    variant="subtitle2">
                    {
                      patientDetailsQuery.data?.data.patientsProvider
                        .providerDetails.officeManagerEmail
                    }
                  </Typography>
                  <Tooltip id="provider-emergency-contact">
                    {
                      patientDetailsQuery.data?.data.patientsProvider
                        .providerDetails.officeManagerEmail
                    }
                  </Tooltip>
                  <Typography className="ellipses-text" variant="subtitle2">
                    {patientDetailsQuery.data?.data.patientsProvider.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {
                      patientDetailsQuery.data.data.patientsProvider
                        .providerDetails.npi
                    }
                  </Typography>
                </div>
              </div>
              <div className="col-span-6 mt-1 flex">
                <div className="flex  flex-col gap-5 font-medium">
                  <Typography variant="subtitle2">DEA</Typography>
                  <Typography variant="subtitle2">Phone</Typography>
                </div>
                <div className="mx-6 flex flex-col gap-5 font-semibold">
                  <Typography variant="subtitle2">:</Typography>
                  <Typography variant="subtitle2">:</Typography>
                </div>
                <div className="flex  flex-col gap-5">
                  <Typography variant="subtitle2">
                    {
                      patientDetailsQuery.data.data.patientsProvider
                        .providerDetails.dea
                    }
                  </Typography>
                  <Typography variant="subtitle2">
                    {formatPhoneNumber(
                      patientDetailsQuery.data.data.patientsProvider.phone
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 grid grow grid-cols-2 gap-5">
        <div className="flex flex-col">
          <Typography className="mb-5" variant="h3">
            Billing Address
          </Typography>
          {patientDetailsQuery.status !== 'success' ? (
            <div className="grid rounded-md bg-background-main p-5">
              <Skeleton height={15} inline />
              <Skeleton height={15} inline width="70%" />
            </div>
          ) : (
            <div className="flex h-full items-start justify-between gap-5 rounded-md bg-background-light p-5">
              <Typography variant="subtitle2">
                {getAddressStatement(
                  patientDetailsQuery.data.data.patientBillingAddress
                )}
              </Typography>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Typography className="mb-5" variant="h3">
            Shipping Address
          </Typography>
          {patientDetailsQuery.status !== 'success' ? (
            <div className="grid rounded-md bg-background-main p-5">
              <Skeleton height={15} inline />
              <Skeleton height={15} inline width="70%" />
            </div>
          ) : (
            <div className="flex h-full items-start justify-between gap-5 rounded-md bg-background-light p-5">
              <Typography variant="subtitle2">
                {getAddressStatement(
                  patientDetailsQuery.data.data.patientShippingAddress
                )}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 grid grow grid-cols-1 gap-5">
        {!orderQuery.isSuccess ? (
          <div className="grid gap-y-3 rounded-md bg-background-main p-5">
            <Skeleton height={25} width={200} />
            <div className="flex justify-between">
              <Skeleton inline width={100} height={20} />
              <Skeleton inline width={100} height={20} />
            </div>
            <Skeleton
              containerClassName="ml-10"
              inline
              width={300}
              height={15}
            />
            <Skeleton
              containerClassName="ml-10 my-3 grid grid-cols-[30%_30%] gap-x-[13rem] gap-y-3"
              inline
              height={15}
              count={4}
            />
            <span className="block h-[0.06px] w-full bg-zinc-500" />
            <Skeleton height={25} width={200} />
            <div className="mb-2 flex justify-between">
              <Skeleton inline width={100} height={20} />
              <Skeleton inline width={100} height={20} />
            </div>
            <span className="block h-[0.06px] w-full bg-zinc-500" />
            <div className="mb-2 flex justify-between">
              <div className="grid gap-y-1">
                <Skeleton inline width={200} height={40} />
                <Skeleton inline width={400} height={10} />
              </div>
              <Skeleton inline width={100} height={20} />
              <Skeleton inline width={100} height={20} />
            </div>
            <div className="mb-2 flex justify-between">
              <Skeleton inline width={400} height={0} />
              <Skeleton inline width={100} height={20} />
              <Skeleton inline width={100} height={20} />
            </div>
          </div>
        ) : (
          <div className="col-span-2 flex justify-between gap-5 rounded-md bg-background-light p-5 ">
            {' '}
            <OrderDetails
              orderDetails={orderQuery.data?.data as unknown as OrderInfo}
            />{' '}
          </div>
        )}
      </div>
    </div>
  );
}
