import { Fragment, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';

type ModalProps = {
  title?: string;
  className?: string;
  children?: ReactNode;
  open: boolean;
  closeModal: (open: boolean) => void;
  disableClickOutside?: boolean;
};

export function Modal({
  title,
  children,
  open,
  closeModal,
  className,
  disableClickOutside,
}: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="light relative z-10"
        onClose={() => {
          if (disableClickOutside) return;
          closeModal(false);
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Dialog.Overlay className="fixed inset-0 bg-background-main bg-opacity-20 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={`transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all ${className}`}>
                <Dialog.Title
                  as="h3"
                  className="font-bold text-background-contrastText">
                  {title}
                </Dialog.Title>
                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
