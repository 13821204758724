export type SubscriptionDetailsType = {
  clientSecret: string;
  amount?: number;
};

export enum SUBSCRIPTION_CHANGE_CODE {
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
  NoChange = 'NoChange',
}
