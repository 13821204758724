import * as yup from 'yup';

const phone = (required = true) => {
  const phoneSchema = yup
    .string()
    .min(11, 'Please enter valid phone')
    .max(13, 'Please enter valid phone');

  return required ? phoneSchema.required('Phone is required') : phoneSchema;
};

const faxNumber = (required = true) => {
  const faxNumberSchema = yup
    .string()
    .max(10, 'Fax number must be 10 digits')
    .min(10, 'Fax number must be 10 digits');

  return required
    ? faxNumberSchema.required('Fax number is required')
    : faxNumberSchema;
};

const zipCode = (required = true) => {
  const zipCodeSchema = yup
    .string()
    .min(5, 'Zip Code must be 5 digits.')
    .max(5, 'Zip Code must be 5 digits.');

  return required
    ? zipCodeSchema.required('Zip Code is required')
    : zipCodeSchema;
};

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
});

export const phoneSchema = yup.object().shape({
  phone: phone(),
});

export const loginCredSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.')
    .required('Please enter password'),
});

export const invitePatientSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  name: yup.string().max(32).required('Please enter name'),
  provider: yup.string().required('Please select a provider'),
});

export const resendInvitePatientSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
});

export const inviteProviderSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  name: yup.string().max(32).required('Please enter name'),
});

export const resentInviteProviderSchema = yup.object().shape({
  name: yup.string().max(32).required('Please enter name'),
});

export const createAddressSchema = yup.object().shape({
  country: yup.string().max(32).required('Country is required'),
  state: yup.string().max(32).required('State is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  addressLine2: yup.string().nullable(),
  city: yup.string().max(32).required('City is required'),
  faxNumber: faxNumber(),
  phone: phone(),
  zipCode: zipCode(),
});

export const updateAddressSchema = yup.object().shape({
  country: yup.string().max(32),
  state: yup.string().max(32),
  addressLine1: yup.string().nullable(),
  addressLine2: yup.string().nullable(),
  city: yup.string().max(32),
  faxNumber: faxNumber(false),
  zipCode: zipCode(false),
  phone: phone(false),
});

export const rxModalSchema = yup.object().shape({
  field: yup
    .string()
    .equals(['CONFIRM'], 'Please enter CONFIRM to proceed')
    .required('Please enter CONFIRM to proceed'),
});

export const createPracticeSchema = yup.object().shape({
  name: yup.string().max(32).required('Please enter name'),
  country: yup.string().max(32).required('Country is required'),
  state: yup.string().max(32).required('State is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  addressLine2: yup.string().nullable(),
  city: yup.string().max(32).required('City is required'),
  faxNumber: faxNumber(),
  phone: phone(),
  zipCode: zipCode(),
});

export const inviteAdminSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  name: yup.string().max(32).required('Please enter name'),
});

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter your new password.')
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export const updatePasswordSchema = yup.object({
  oldPassword: yup.string().required('Please enter your old password.'),
  newPassword: yup
    .string()
    .required('Please enter your new password.')
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
});
export const addressSchema = yup.object({
  addressLine1: yup.string().required('Please enter address.'),
  addressLine2: yup.string().nullable(),
  city: yup.string().required('Please enter city.'),
  country: yup.string().required('Please enter country.'),
  state: yup.string().required('Please enter state.'),
  zipCode: zipCode(),
});

export const practiceAdminOnboardSchema = yup.object().shape({
  firstName: yup.string().max(32).required('Please enter first name'),
  lastName: yup.string().max(32).required('Please enter last name'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.')
    .required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  phone: phone(),
});
