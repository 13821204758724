import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { Typography } from 'components/common/Typography/Typography';
import { AddPaymentMethodModal } from 'components/subscription/AddPaymentMethodModal/AddPaymentMethodModal';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { BillingService } from 'services/billing';

type PaymentMethodProps = {
  patientDetails: {
    email: string;
    name: string;
  };
  paymentMethods: {
    id: string;
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
    isDefault: boolean;
  }[];
  hasActiveSubscription: boolean;
};

export function PaymentMethods({
  paymentMethods,
  patientDetails,
  hasActiveSubscription,
}: PaymentMethodProps) {
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const [clickedButtonPaymentId, setClickedButtonPaymentId] =
    useState<string>();
  const [isRefetchingPaymentMethod, setIsRefetchingPaymentMethod] =
    useState(false);
  const { id: patientId } = useParams();
  const paymentMethodMutation = useMutationWithToast(
    BillingService.setDefaultPaymentMethod
  );

  const queryClient = useQueryClient();

  const makeDefault = (id: string) => {
    if (!patientId) return;
    paymentMethodMutation.mutate(
      { patientId, paymentMethodId: id },
      {
        onSuccess: () => {
          setIsRefetchingPaymentMethod(true);
          queryClient
            .refetchQueries(QueryKeys.PaymentMethod.item(patientId))
            .then(() => {
              setIsRefetchingPaymentMethod(false);
            });
        },
      }
    );
  };

  const handleCardAdded = () => {
    if (!patientId) return;

    setIsRefetchingPaymentMethod(true);
    queryClient
      .refetchQueries(QueryKeys.PaymentMethod.item(patientId))
      .finally(() => {
        setIsRefetchingPaymentMethod(false);
        setOpenAddCardModal(false);
      });
  };

  return (
    <div className="flex flex-grow flex-col rounded-md bg-background-main p-5">
      <Typography className="mb-5" variant="h4">
        Saved Payment Methods
      </Typography>
      <div className="scrollbar-dark mb-5 flex max-h-[14rem] flex-col space-y-2 overflow-auto pr-2">
        {paymentMethods.length ? (
          paymentMethods.map((paymentMethod) => (
            <div className="flex justify-between rounded-md bg-background-light p-3">
              <div className="flex flex-col space-y-3">
                <Typography className="capitalize" variant="h4">
                  {paymentMethod.brand}
                </Typography>
                <Typography variant="subtitle2">
                  **** **** **** {paymentMethod.last4}
                </Typography>
                <Typography variant="h5">
                  Expires on : {paymentMethod.expMonth}/
                  {String(paymentMethod.expYear).slice(2)}
                </Typography>
              </div>
              {paymentMethod.isDefault ? (
                <div className="h-max w-max rounded-full border border-red-500 px-5 py-1">
                  <Typography
                    color="error"
                    className="!text-sm text-red-500"
                    variant="h5">
                    Default
                  </Typography>
                </div>
              ) : (
                <Button
                  size="small"
                  className="h-max w-max"
                  onClick={() => {
                    setClickedButtonPaymentId(paymentMethod.id);
                    makeDefault(paymentMethod.id);
                  }}
                  loading={
                    clickedButtonPaymentId === paymentMethod.id &&
                    (paymentMethodMutation.isLoading ||
                      isRefetchingPaymentMethod)
                  }
                  variant="contained">
                  Make Default
                </Button>
              )}
            </div>
          ))
        ) : (
          <Typography variant="subtitle2">
            No saved payment methods found.
          </Typography>
        )}
      </div>
      <span
        className="mx-auto inline-block"
        data-tooltip-id="add_payment_method_button">
        <Button
          disabled={!hasActiveSubscription}
          onClick={() => setOpenAddCardModal(true)}
          className="w-max "
          variant="contained">
          Add Payment Method
        </Button>
      </span>
      {!hasActiveSubscription && (
        <Tooltip id="add_payment_method_button">
          No active subscription found.
        </Tooltip>
      )}
      <AddPaymentMethodModal
        onCardAdded={handleCardAdded}
        patientDetails={patientDetails}
        openModal={openAddCardModal}
        onModalClose={() => {
          setOpenAddCardModal(false);
        }}
      />
    </div>
  );
}
