import classNames from 'classnames';

import { TableHeadItemProps } from './types';

export function TableHeadItem<TItem>({
  column,
  isSticky,
  columnIndex,
  columnsLength,
}: TableHeadItemProps<TItem>): JSX.Element {
  return (
    <th
      scope="col"
      className={classNames(
        `whitespace-nowrap px-6 py-3 text-md ${column?.classNames || ''}`,
        {
          ' sticky bg-primary-main ': isSticky,
          ' left-0 shadow-[10px_-15px_0px_15px_rgba(53,149,131,1)]':
            columnIndex === 0,
          ' left-32 ': columnIndex === 1,
          ' right-[-0.3px] ': columnIndex === columnsLength - 1,
        }
      )}>
      {column.title}
    </th>
  );
}
